import React from "react";
import Tippy from '@tippyjs/react';

export default ({ errors }) => {
  if (errors) {
    return (
      Object.keys(errors).length > 0 ? (
        <Tippy content={Object.values(errors).join(", ")}>
          <i className="far fa-circle-exclamation" style={{color: "#DC6803"}}/>
        </Tippy>
      ) : null
    );
  } else {
    return null;
  }
};
