import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { ticketTypeAvailable, salesStartInFuture } from './WebOrderForm';
import CalendarEventInterestForm from './CalendarEventInterestForm';

const ticketTypeDisabled = (ticketType) => {
  return (
    ticketType.sales_ended || ticketType.sold_out
  );
}

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const ChooseTicketTypes = ({
  team,
  csrfToken,
  confirm,
  ticketTypes,
  promoCode,
  ticketTypesChanged,
  buildTicketReservation,
  visibleTicketTypes,
  addOns,
  ticketReservation,
  postToParent,
  formatItemsForGoogleTagManager,
  userContext,
  announceListEnabled,
  waitlistEnabled,
}) => {
  const [showPresalePrompt, setShowPresalePrompt] = useState(false);
  const [availibleTickets, setAvailibleTickets] = useState([]);
  const [visibleTickets, setVisibleTickets] = useState([]);
  const [scheduledTickets, setScheduledTickets] = useState([]);

  useEffect(() => {
    setVisibleTickets(
      visibleTicketTypes(confirm, ticketTypes, promoCode)
    );
  }, [ticketTypes, promoCode]);

  useEffect(() => {
    setAvailibleTickets(
      visibleTickets.filter((ticketType) => ticketTypeAvailable(ticketType))
    );

    setScheduledTickets(
      visibleTickets
        .filter((ticketType) => ticketType.scheduled)
        .sort((a, b) => new Date(a.start_time) - new Date(b.start_time))
    );
  }, [visibleTickets]);

  useEffect(() => {
    setShowPresalePrompt(
      confirm.preselling && Object.keys(promoCode).length === 0
    );
  }, [confirm, promoCode]);

  return (
    <div>
      {showPresalePrompt ? (
        <div className="card border-0"
             style={{"background": "#f9f9f9", "marginBottom": "15px"}}>
          <div className="card-body"
               style={{
                 "paddingLeft": "15px",
                 "paddingRight": "15px"
               }}>
            <div className="row">
              <div className="col">
                <p className="mb-0"
                   style={{"fontSize": "16px"}}>
                  <strong>
                    <i className="fas fa-lock text-primary mr-2"></i>
                    Presale Ticket Available
                  </strong>
                </p>
              </div>
              <div className="col d-flex align-items-center justify-content-end">
                <p className="mb-0"
                   style={{"fontSize": "14px", "color": "#686868"}}>
                  Enter Pre-Sale Code Above
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {visibleTickets.length > 0 && visibleTickets.map((ticketType, index) =>
        <div className="card border-0"
             style={{"background": "#f9f9f9", "marginBottom": "15px"}}
             key={index}>
          <div className="card-body"
               style={{
                 "paddingLeft": "15px",
                 "paddingRight": "15px"
               }}>
            <div className="row"
                 style={ticketTypeDisabled(ticketType) ? {"color": "#686868"} : {}}>
              <div className="col">
                <p className="mb-0"
                   style={{"fontSize": "16px"}}>
                  <strong>{ticketType.name}</strong>
                </p>
                <p className="mb-0"
                   style={{"fontSize": "16px"}}>
                  {ticketType.free ? (
                    <span>FREE</span>
                  ) : (
                    <span>
                      {formatter.format(parseFloat(ticketType.calculated_price))}
                    </span>
                  )}
                  {confirm.enable_all_in_pricing ? (
                    <p className="mt-2 mb-0 small text-muted">
                      <i className="fas fa-info-circle mr-1"></i>
                      Price includes all fees, including taxes if applicable
                    </p>
                  ) : null}
                </p>
                {ticketType.description && ticketType.description.length > 0 ? (
                  <div className="mb-0 mt-2 small last-p-margin-0"
                       dangerouslySetInnerHTML={{__html: ticketType.simple_format_description}}
                       style={{"maxWidth": "400px"}}>
                  </div>
                ) : null}
              </div>
              {ticketType.scheduled ? (
                <div className="col d-flex align-items-center justify-content-end">
                  <p className="mb-0"
                     style={{"fontSize": "14px", "color": "#686868"}}>
                    {ticketType.wait_for_ticket_type && ticketType.wait_for_ticket_type.id ? (
                      <span>Sales not started</span>
                    ) : (
                      <span>Sales start {ticketType.human_sales_start_date}</span>
                    )}
                  </p>
                </div>
              ) : ticketType.sales_ended ? (
                <div className="col d-flex align-items-center justify-content-end">
                  <p className="mb-0"
                     style={{"fontSize": "14px", "color": "#686868"}}>
                    Sale ended
                  </p>
                </div>
              ) : ticketType.sold_out ? (
                <div className="col d-flex align-items-center justify-content-end">
                  <p className="mb-0"
                     style={{"fontSize": "14px", "color": "#686868"}}>
                    Sold out
                  </p>
                </div>
              ) : (
                <div className="col-xs-auto d-flex justify-content-end"
                     style={{"paddingRight": "15px"}}>
                  <div className="form-group mb-0">
                    <select className="form-control form-control-lg"
                            aria-label="Ticket Quantity"
                            onChange={
                              (e) => {
                                var quantity = parseInt(e.target.value);

                                var updated = [...ticketTypes].map((tt) => {
                                  if(tt.id === ticketType.id){
                                    return Object.assign({}, tt, {quantity: quantity});
                                  } else {
                                    return tt;
                                  }
                                });

                                if(quantity > 0){
                                  var updatedItem = Object.assign({}, ticketType, {
                                    quantity: quantity
                                  });

                                  var items = formatItemsForGoogleTagManager(
                                    confirm,
                                    promoCode,
                                    [updatedItem],
                                    []
                                  );

                                  postToParent("ga.add_to_cart", items)
                                }

                                ticketTypesChanged(updated);
                                buildTicketReservation(csrfToken, confirm, updated, promoCode, addOns, ticketReservation, userContext);
                              }
                            }
                            value={ticketType.quantity || 0}
                            style={{"fontSize": "16px", "border": "1px solid #f3f3f3"}}>
                      {(ticketType.current_quantity_options || ticketType.quantity_options).map((quantity, index) =>
                        <option key={index} value={quantity}>
                          {quantity}
                        </option>
                      )}
                    </select>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {availibleTickets.length === 0 ? (
        salesStartInFuture(confirm) || confirm.preselling ? (
          announceListEnabled ? (
            <CalendarEventInterestForm
              confirm={confirm}
              csrfToken={csrfToken}
              interestType="Announce"
            />
          ) : (
            <div className="card border-0 h-100" style={{"background": "#f9f9f9"}}>
              <div className="card-body d-flex align-items-center justify-content-center">
                <div className="text-center">
                  <p className="mb-2"
                    style={{"fontSize": "16px"}}>
                    <strong>Coming soon!</strong>
                  </p>
                  <p className="mb-0">
                    Sales not started yet.
                  </p>
                </div>
              </div>
            </div>
          )
        ) : (
          waitlistEnabled ? (
            <CalendarEventInterestForm
              confirm={confirm}
              csrfToken={csrfToken}
              interestType="Waitlist"
            />
          ) : (
            <div className="card border-0 h-100"
                  style={{"background": "#f9f9f9"}}>
              <div className="card-body d-flex align-items-center justify-content-center">
                <div className="text-center">
                  <p className="mb-2"
                      style={{"fontSize": "16px"}}>
                    <strong>Tickets not currently available</strong>
                  </p>
                  {scheduledTickets.length > 0 ? (
                    <>
                      <p className="mb-0">
                        Online sales for this event are currently not available.
                      </p>
                      <p className="mb-0">
                        {`The next sale will begin on ${scheduledTickets[0].human_start_time}.`}
                      </p>
                    </>
                  ) : (
                    <p className="mb-0">
                      Online sales for this event have ended.
                    </p>
                  )}
                </div>
              </div>
            </div>
          )
        )
      ): null}
    </div>
  )
};

ChooseTicketTypes.propTypes = {
  team: PropTypes.object.isRequired,
  csrfToken: PropTypes.string.isRequired,
  confirm: PropTypes.object.isRequired,
  ticketTypes: PropTypes.array.isRequired,
  promoCode: PropTypes.object,
  ticketTypesChanged: PropTypes.func.isRequired,
  buildTicketReservation: PropTypes.func.isRequired,
  visibleTicketTypes: PropTypes.func.isRequired,
  addOns: PropTypes.array,
  ticketReservation: PropTypes.object,
  postToParent: PropTypes.func.isRequired,
  formatItemsForGoogleTagManager: PropTypes.func.isRequired,
  userContext: PropTypes.object,
  announceListEnabled: PropTypes.bool,
  waitlistEnabled: PropTypes.bool,
};

export default ChooseTicketTypes;
