import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { ticketTypeAvailable, salesStartInFuture } from './WebOrderForm';
import ChooseSeatedAddOns from './ChooseSeatedAddOns';
import ChooseSeatedTicketTypes from './ChooseSeatedTicketTypes';
import ChooseDonation from './ChooseDonation';
import CalendarEventInterestForm from './CalendarEventInterestForm';

const ticketTypeDisabled = (ticketType) => {
  return (
    ticketType.sales_ended || ticketType.sold_out
  );
}

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const TicketTypeRow = ({
  checkoutGroupItem,
  visibleTickets,
  promoCode,
  ticketTypes,
  formatItemsForGoogleTagManager,
  postToParent,
  ticketTypesChanged,
  buildTicketReservation,
  csrfToken,
  addOns,
  ticketReservation,
  userContext,
  confirm,
}) => {
  const ticketType = visibleTickets
    .find((tt) => tt.id === checkoutGroupItem.item_id);

  return (
    ticketType ? (
      <tr className={(ticketTypeDisabled(ticketType) ? "disabled " : "") + "ticket-type-row"}>
        <td>
          <p className="mb-0">
            {ticketType.name}
          </p>
          {confirm.enable_all_in_pricing ? (
            <p className="mt-2 mb-0 small text-muted">
              <i className="fas fa-info-circle mr-1"></i>
              Price includes all fees, including taxes if applicable
            </p>
          ) : null}
          {ticketType.description ? (
            <div
              className="small"
              dangerouslySetInnerHTML={{ __html: ticketType.simple_format_description }}
            />
          ) : null}
        </td>
        <td>
          <p className="mb-0">
            {ticketType.free ? (
              <span>FREE</span>
            ) : (
              <span>
                {formatter.format(parseFloat(ticketType.calculated_price))}
              </span>
            )}
          </p>
        </td>
        <td>
          {ticketType.scheduled ? (
            <div className="col d-flex justify-content-end">
              <p className="mb-0"
                style={{"color": "#686868"}}>
                {ticketType.wait_for_ticket_type && ticketType.wait_for_ticket_type.id ? (
                  <span>Sales not started</span>
                ) : (
                  <span>Sales start {ticketType.human_sales_start_date}</span>
                )}
              </p>
            </div>
          ) : ticketType.sales_ended ? (
            <div className="col d-flex justify-content-end">
              <p className="mb-0"
                style={{"color": "#686868"}}>
                Sale ended
              </p>
            </div>
          ) : ticketType.sold_out ? (
            <div className="col d-flex justify-content-end">
              <p className="mb-0"
                style={{"color": "#686868"}}>
                Sold out
              </p>
            </div>
          ) : (
            <div className="col-xs-auto d-flex justify-content-end"
                style={{"paddingRight": "15px"}}>
              <div className="form-group mb-0">
                <select className="form-control form-control-sm"
                        aria-label="Ticket Quantity"
                        onChange={
                          (e) => {
                            var quantity = parseInt(e.target.value);

                            var updated = [...ticketTypes].map((tt) => {
                              if(tt.id === ticketType.id){
                                return Object.assign({}, tt, {quantity: quantity});
                              } else {
                                return tt;
                              }
                            });

                            if(quantity > 0){
                              var updatedItem = Object.assign({}, ticketType, {
                                quantity: quantity
                              });

                              var items = formatItemsForGoogleTagManager(
                                confirm,
                                promoCode,
                                [updatedItem],
                                []
                              );

                              postToParent("ga.add_to_cart", items)
                            }

                            ticketTypesChanged(updated);
                            buildTicketReservation(csrfToken, confirm, updated, promoCode, addOns, ticketReservation, userContext);
                          }
                        }
                        value={ticketType.quantity || 0}
                        style={{"border": "1px solid #f3f3f3"}}>
                  {(ticketType.current_quantity_options || ticketType.quantity_options).map((quantity, index) =>
                    <option key={index} value={quantity}>
                      {quantity}
                    </option>
                  )}
                </select>
              </div>
            </div>
          )}
        </td>
      </tr>
    ) : null
  );
}

const AddOnRow = ({
  team,
  csrfToken,
  confirm,
  ticketTypes,
  addOns,
  buildTicketReservation,
  visibleAddOns,
  promoCode,
  addOnsChanged,
  addOnQuantityOptions,
  ticketReservation,
  userContext,
  postToParent,
  formatItemsForGoogleTagManager,
  checkoutGroupItem
}) => {
  const addOn = visibleAddOns
    .find((ao) => ao.id === checkoutGroupItem.item_id);

  return (
    addOn ? (
      <tr className="add-on-row">
        <td>
          <p className="mb-0">
            {addOn.name}
          </p>
          {confirm.enable_all_in_pricing ? (
            <p className="mt-2 mb-0 small text-muted">
              <i className="fas fa-info-circle mr-1"></i>
              Price includes all fees, including taxes if applicable
            </p>
          ) : null}
          {addOn.description ? (
            <div
              className="small"
              dangerouslySetInnerHTML={{ __html: addOn.simple_format_description }}
            />
          ) : null}
        </td>
        <td>
          <p className="mb-0">
            {formatter.format(parseFloat(addOn.calculated_price))}
          </p>
        </td>
        <td>
          {(addOn.available_tickets == 0 || addOn.sold_out) ? (
            <div className="col d-flex justify-content-end">
              <p className="mb-0"
                style={{"color": "#686868"}}>
                Sold out
              </p>
            </div>
          ) : (
            <div className="col-xs-auto d-flex justify-content-end"
                style={{"paddingRight": "15px"}}>
              <div className="form-group mb-0">
                <select className="form-control form-control-sm"
                        aria-label="Add-on Quantity"
                        onChange={
                          (e) => {
                            var quantity = parseInt(e.target.value);

                            var updated = [...addOns].map((ao) => {
                              if(ao.id === addOn.id){
                                return Object.assign({}, ao, {quantity: quantity});
                              } else {
                                return ao;
                              }
                            });

                            if(quantity > 0){
                              var updatedItem = Object.assign({}, addOn, {
                                quantity: quantity
                              });

                              var items = formatItemsForGoogleTagManager(
                                confirm,
                                promoCode,
                                [],
                                [updatedItem]
                              );

                              postToParent("ga.add_to_cart", items)
                            }

                            addOnsChanged(updated);
                            buildTicketReservation(csrfToken, confirm, ticketTypes, promoCode, updated, ticketReservation, userContext);
                          }
                        }
                        value={addOn.quantity || 0}
                        style={{"border": "1px solid #f3f3f3"}}>
                  {addOnQuantityOptions(addOn, ticketTypes, userContext).map((quantity, index) =>
                    <option key={index} value={quantity}>
                      {quantity}
                    </option>
                  )}
                </select>
              </div>
            </div>
          )}
        </td>
      </tr>
    ) : null
  );
};

const CheckoutGroupItemRow = ({
  team,
  checkoutGroupItem,
  visibleTickets,
  promoCode,
  ticketTypes,
  formatItemsForGoogleTagManager,
  postToParent,
  ticketTypesChanged,
  buildTicketReservation,
  csrfToken,
  addOns,
  ticketReservation,
  userContext,
  confirm,
  addOnsChanged,
  addOnQuantityOptions,
  visibleAddOns,
  seatedAddOns,
  seatsIOPublicKey,
  selectedObjectLabel,
  selectedObjectType,
  seatsIOChart,
  seatsIOChartChanged,
  seatsIOSelectedObjects,
  seatsIOSelectedObjectsChanged,
  onSelectionChange,
  no_orphan_seats,
  visibleTicketTypes,
  announceListEnabled,
  waitlistEnabled,
  donationCustomChargeType,
  donationCustomChargeTypeChanged,
  debouncedBuildTicketReservation,
  feeRules,
  donationTitle,
  donationCoverProcessingText
}) => {
  return (
    checkoutGroupItem.enable_donation ? (
      <tr>
        <td colSpan={3}>
          <ChooseDonation team={team}
                          csrfToken={csrfToken}
                          confirm={confirm}
                          buildTicketReservation={buildTicketReservation}
                          donationCustomChargeType={donationCustomChargeType}
                          donationCustomChargeTypeChanged={donationCustomChargeTypeChanged}
                          ticketTypes={ticketTypes}
                          addOns={addOns}
                          promoCode={promoCode}
                          userContext={userContext}
                          debouncedBuildTicketReservation={debouncedBuildTicketReservation}
                          feeRules={feeRules}
                          donationTitle={confirm && confirm.donation_title ? confirm.donation_title : "Make a Donation"}
                          donationCoverProcessingText={confirm && confirm.donation_cover_processing_text ? confirm.donation_cover_processing_text : `I'll cover processing costs so that my full donation goes to ${confirm.venue.name}`}
                          marginBottom={"0px"}
                          ticketReservation={ticketReservation} />
        </td>
      </tr>
    ) : checkoutGroupItem.seatsio_event_id && checkoutGroupItem.seatsio_event_id.length > 0 ? (
      <tr>
        <td colSpan={3}>
          <ChooseSeatedTicketTypes team={team}
                                    csrfToken={csrfToken}
                                    confirm={confirm}
                                    ticketTypes={ticketTypes}
                                    promoCode={promoCode}
                                    ticketTypesChanged={ticketTypesChanged}
                                    buildTicketReservation={buildTicketReservation}
                                    userContext={userContext}
                                    addOns={addOns}
                                    ticketReservation={ticketReservation}
                                    postToParent={postToParent}
                                    formatItemsForGoogleTagManager={formatItemsForGoogleTagManager}
                                    seatsIOPublicKey={seatsIOPublicKey}
                                    seatsIOChart={seatsIOChart}
                                    seatsIOChartChanged={seatsIOChartChanged}
                                    seatsIOSelectedObjects={seatsIOSelectedObjects}
                                    seatsIOSelectedObjectsChanged={seatsIOSelectedObjectsChanged}
                                    selectedObjectLabel={selectedObjectLabel}
                                    selectedObjectType={selectedObjectType}
                                    visibleTicketTypes={visibleTicketTypes}
                                    onSelectionChange={onSelectionChange}
                                    announceListEnabled={false}
                                    waitlistEnabled={false}
                                    no_orphan_seats={no_orphan_seats} />
        </td>
      </tr>
    ) : checkoutGroupItem.add_on_seatsio_event_id && checkoutGroupItem.add_on_seatsio_event_id.length > 0 && seatedAddOns.length > 0 ? (
      visibleAddOns.length > 0 ? (
        <tr>
          <td colSpan={3}>
            <ChooseSeatedAddOns team={team}
                                csrfToken={csrfToken}
                                confirm={confirm}
                                ticketTypes={ticketTypes}
                                addOns={addOns}
                                addOnsChanged={addOnsChanged}
                                buildTicketReservation={buildTicketReservation}
                                promoCode={promoCode}
                                ticketReservation={ticketReservation}
                                userContext={userContext}
                                postToParent={postToParent}
                                formatItemsForGoogleTagManager={formatItemsForGoogleTagManager}
                                seatsIOPublicKey={seatsIOPublicKey}
                                selectedObjectLabel={selectedObjectLabel}
                                selectedObjectType={selectedObjectType}
                                visibleAddOns={visibleAddOns} />
          </td>
        </tr>
      ) : null
    ) : checkoutGroupItem.item_type === "SeatingChartEvent" && checkoutGroupItem.extra.seatsio_event_id && checkoutGroupItem.extra.seatsio_event_id.length > 0 && seatedAddOns.length > 0 ? (
      visibleAddOns.length > 0 ? (
        <tr>
          <td colSpan={3}>
            <ChooseSeatedAddOns team={team}
                                csrfToken={csrfToken}
                                confirm={confirm}
                                ticketTypes={ticketTypes}
                                addOns={addOns}
                                addOnsChanged={addOnsChanged}
                                buildTicketReservation={buildTicketReservation}
                                promoCode={promoCode}
                                ticketReservation={ticketReservation}
                                userContext={userContext}
                                postToParent={postToParent}
                                formatItemsForGoogleTagManager={formatItemsForGoogleTagManager}
                                seatsIOPublicKey={seatsIOPublicKey}
                                selectedObjectLabel={selectedObjectLabel}
                                selectedObjectType={selectedObjectType}
                                checkoutGroupItem={checkoutGroupItem}
                                visibleAddOns={visibleAddOns} />
          </td>
        </tr>
      ) : null
    ) : checkoutGroupItem.item_type === "TicketType" ? (
      <TicketTypeRow promoCode={promoCode}
                    checkoutGroupItem={checkoutGroupItem}
                    visibleTickets={visibleTickets}
                    ticketTypes={ticketTypes}
                    formatItemsForGoogleTagManager={formatItemsForGoogleTagManager}
                    postToParent={postToParent}
                    ticketTypesChanged={ticketTypesChanged}
                    buildTicketReservation={buildTicketReservation}
                    csrfToken={csrfToken}
                    addOns={addOns}
                    ticketReservation={ticketReservation}
                    userContext={userContext}
                    confirm={confirm} />
    ) : checkoutGroupItem.item_type === "AddOn" ? (
      <AddOnRow team={team}
                csrfToken={csrfToken}
                confirm={confirm}
                ticketTypes={ticketTypes}
                addOns={addOns}
                buildTicketReservation={buildTicketReservation}
                visibleAddOns={visibleAddOns}
                promoCode={promoCode}
                addOnsChanged={addOnsChanged}
                addOnQuantityOptions={addOnQuantityOptions}
                ticketReservation={ticketReservation}
                userContext={userContext}
                postToParent={postToParent}
                formatItemsForGoogleTagManager={formatItemsForGoogleTagManager}
                checkoutGroupItem={checkoutGroupItem} />
    ) :null
  );
}

const ChooseCheckoutCategories = ({
  team,
  csrfToken,
  confirm,
  ticketTypes,
  promoCode,
  ticketTypesChanged,
  buildTicketReservation,
  visibleTicketTypes,
  addOns,
  ticketReservation,
  postToParent,
  formatItemsForGoogleTagManager,
  userContext,
  announceListEnabled,
  waitlistEnabled,
  checkoutCategories,
  activeCategory,
  setActiveCategory,
  addOnsChanged,
  addOnQuantityOptions,
  visibleAddOns,
  seatedAddOns,
  seatsIOPublicKey,
  selectedObjectLabel,
  selectedObjectType,
  seatsIOChart,
  seatsIOChartChanged,
  seatsIOSelectedObjects,
  seatsIOSelectedObjectsChanged,
  onSelectionChange,
  no_orphan_seats,
  donationCustomChargeType,
  donationCustomChargeTypeChanged,
  debouncedBuildTicketReservation,
  feeRules,
  donationTitle,
  donationCoverProcessingText
}) => {
  const [showPresalePrompt, setShowPresalePrompt] = useState(false);
  const [availibleTickets, setAvailibleTickets] = useState([]);
  const [visibleTickets, setVisibleTickets] = useState([]);
  const [scheduledTickets, setScheduledTickets] = useState([]);

  useEffect(() => {
    setVisibleTickets(
      visibleTicketTypes(confirm, ticketTypes, promoCode)
    );
  }, [ticketTypes, promoCode]);

  useEffect(() => {
    setAvailibleTickets(
      visibleTickets.filter((ticketType) => ticketTypeAvailable(ticketType))
    );

    setScheduledTickets(
      visibleTickets
        .filter((ticketType) => ticketType.scheduled)
        .sort((a, b) => new Date(a.start_time) - new Date(b.start_time))
    );
  }, [visibleTickets]);

  useEffect(() => {
    setShowPresalePrompt(
      confirm.preselling && Object.keys(promoCode).length === 0
    );
  }, [confirm, promoCode]);

  const showCheckoutGroup = (checkoutCategory, checkoutGroup) => {
    var hasAtLeastOneVisibleItem = checkoutGroup.checkout_group_items.some((checkoutGroupItem) => {
      var isVisible = false;

      switch (checkoutGroupItem.item_type) {
        case 'TicketType':
          isVisible = visibleTickets.find((tt) => tt.id === checkoutGroupItem.item_id) !== undefined;
          break;
        case 'AddOn':
          isVisible = visibleAddOns.find((ao) => ao.id === checkoutGroupItem.item_id) !== undefined;
          break;
        default:
          isVisible = (
            checkoutGroupItem.seatsio_event_id
              || checkoutGroupItem.add_on_seatsio_event_id
              || checkoutGroupItem.enable_donation
              || checkoutGroupItem.item_type === "SeatingChartEvent"
          );
      }

      return isVisible;
    });

    return (
      activeCategory.id === checkoutCategory.id
        && hasAtLeastOneVisibleItem
    );
  }

  return (
    <div>
      {showPresalePrompt ? (
        <div className="card border-0"
             style={{"background": "#f9f9f9", "marginBottom": "15px"}}>
          <div className="card-body"
               style={{
                 "paddingLeft": "15px",
                 "paddingRight": "15px"
               }}>
            <div className="row">
              <div className="col">
                <p className="mb-0"
                   style={{"fontSize": "16px"}}>
                  <strong>
                    <i className="fas fa-lock text-primary mr-2"></i>
                    Presale Ticket Available
                  </strong>
                </p>
              </div>
              <div className="col d-flex align-items-center justify-content-end">
                <p className="mb-0"
                   style={{"fontSize": "14px", "color": "#686868"}}>
                  Enter Pre-Sale Code Above
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <ul className="nav inverse-nav-tabs px-3 bottom-border"
          style={{"marginBottom": "20px"}}>
        {checkoutCategories.map((category, index) =>
          <li key={index}
              className="nav-item"
              style={{"marginBottom": "-2px"}}>
            <a className={"nav-link pb-3 " + (activeCategory.id === category.id ? "active" : "")}
                onClick={
                  (e) => {
                    e.preventDefault();
                    setActiveCategory(category);
                  }
                }
                href="#">
              <strong>{category.name}</strong>
            </a>
          </li>
        )}
      </ul>
      {availibleTickets.length === 0 ? (
        <div style={{"marginBottom": "20px"}}>
          {salesStartInFuture(confirm) || confirm.preselling ? (
            announceListEnabled ? (
              <CalendarEventInterestForm
                confirm={confirm}
                csrfToken={csrfToken}
                interestType="Announce"
              />
            ) : (
              <div className="card border-0 h-100" style={{"background": "#f9f9f9"}}>
                <div className="card-body d-flex align-items-center justify-content-center">
                  <div className="text-center">
                    <p className="mb-2"
                      style={{"fontSize": "16px"}}>
                      <strong>Coming soon!</strong>
                    </p>
                    <p className="mb-0">
                      Sales not started yet.
                    </p>
                  </div>
                </div>
              </div>
            )
          ) : (
            waitlistEnabled ? (
              <CalendarEventInterestForm
                confirm={confirm}
                csrfToken={csrfToken}
                interestType="Waitlist"
              />
            ) : (
              <div className="card border-0 h-100"
                    style={{"background": "#f9f9f9"}}>
                <div className="card-body d-flex align-items-center justify-content-center">
                  <div className="text-center">
                    <p className="mb-2"
                        style={{"fontSize": "16px"}}>
                      <strong>Tickets not currently available</strong>
                    </p>
                    {scheduledTickets.length > 0 ? (
                      <>
                        <p className="mb-0">
                          Online sales for this event are currently not available.
                        </p>
                        <p className="mb-0">
                          {`The next sale will begin on ${scheduledTickets[0].human_start_time}.`}
                        </p>
                      </>
                    ) : (
                      <p className="mb-0">
                        Online sales for this event have ended.
                      </p>
                    )}
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      ): null}
      {checkoutCategories.map((category) =>
        category.checkout_groups.map((checkoutGroup, index) =>
          <div key={index}
                style={{
                  "border": "1px solid #ECECEC",
                  "borderRadius": "8px"
                }}
                className={"card " + (showCheckoutGroup(category, checkoutGroup) ? "" : "d-none")}>
            <div className="card-header border-0">
              <p className='mb-0'
                  style={{"fontSize": "16px"}}>
                <strong>{checkoutGroup.name}</strong>
              </p>
            </div>
            {checkoutGroup.description && checkoutGroup.description.length > 0 ? (
              <div className='card-body pb-0'>
                <div className='small'
                      dangerouslySetInnerHTML={{__html: checkoutGroup.formatted_description}}>
                </div>
              </div>
            ) : null}
            <div className='card-body'>
              <table className='table table-sm vertical-middle mb-0 table-borderless'>
                <colgroup>
                  <col span={1} style={{"width": "60%"}} />
                  <col span={1} style={{"width": "20%"}} />
                  <col span={1} style={{"width": "20%"}} />
                </colgroup>
                <tbody>
                  {checkoutGroup.checkout_group_items.map((checkoutGroupItem, index) =>
                    <CheckoutGroupItemRow key={index}
                                          team={team}
                                          visibleTickets={visibleTickets}
                                          promoCode={promoCode}
                                          ticketTypes={ticketTypes}
                                          formatItemsForGoogleTagManager={formatItemsForGoogleTagManager}
                                          postToParent={postToParent}
                                          ticketTypesChanged={ticketTypesChanged}
                                          buildTicketReservation={buildTicketReservation}
                                          csrfToken={csrfToken}
                                          addOns={addOns}
                                          ticketReservation={ticketReservation}
                                          userContext={userContext}
                                          confirm={confirm}
                                          addOnsChanged={addOnsChanged}
                                          addOnQuantityOptions={addOnQuantityOptions}
                                          visibleAddOns={visibleAddOns}
                                          seatedAddOns={seatedAddOns}
                                          seatsIOPublicKey={seatsIOPublicKey}
                                          selectedObjectLabel={selectedObjectLabel}
                                          selectedObjectType={selectedObjectType}
                                          seatsIOChart={seatsIOChart}
                                          seatsIOChartChanged={seatsIOChartChanged}
                                          seatsIOSelectedObjects={seatsIOSelectedObjects}
                                          seatsIOSelectedObjectsChanged={seatsIOSelectedObjectsChanged}
                                          onSelectionChange={onSelectionChange}
                                          no_orphan_seats={no_orphan_seats}
                                          visibleTicketTypes={visibleTicketTypes}
                                          announceListEnabled={announceListEnabled}
                                          waitlistEnabled={waitlistEnabled}
                                          donationCustomChargeType={donationCustomChargeType}
                                          donationCustomChargeTypeChanged={donationCustomChargeTypeChanged}
                                          debouncedBuildTicketReservation={debouncedBuildTicketReservation}
                                          feeRules={feeRules}
                                          donationTitle={donationTitle}
                                          donationCoverProcessingText={donationCoverProcessingText}
                                          checkoutGroupItem={checkoutGroupItem} />
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )
      )}
    </div>
  )
};

ChooseCheckoutCategories.propTypes = {
  team: PropTypes.object.isRequired,
  csrfToken: PropTypes.string.isRequired,
  confirm: PropTypes.object.isRequired,
  ticketTypes: PropTypes.array.isRequired,
  promoCode: PropTypes.object,
  ticketTypesChanged: PropTypes.func.isRequired,
  buildTicketReservation: PropTypes.func.isRequired,
  visibleTicketTypes: PropTypes.func.isRequired,
  addOns: PropTypes.array,
  ticketReservation: PropTypes.object,
  postToParent: PropTypes.func.isRequired,
  formatItemsForGoogleTagManager: PropTypes.func.isRequired,
  userContext: PropTypes.object,
  announceListEnabled: PropTypes.bool,
  waitlistEnabled: PropTypes.bool,
  checkoutCategories: PropTypes.array,
  addOnsChanged: PropTypes.func.isRequired,
  addOnQuantityOptions: PropTypes.func.isRequired,
  visibleAddOns: PropTypes.array,
  seatedAddOns: PropTypes.array,
  seatsIOPublicKey: PropTypes.string,
  selectedObjectLabel: PropTypes.func.isRequired,
  selectedObjectType: PropTypes.func.isRequired,
  seatsIOChart: PropTypes.object,
  seatsIOChartChanged: PropTypes.func.isRequired,
  seatsIOSelectedObjects: PropTypes.array,
  seatsIOSelectedObjectsChanged: PropTypes.func.isRequired,
  onSelectionChange: PropTypes.func,
  no_orphan_seats: PropTypes.bool.isRequired,
  donationCustomChargeType: PropTypes.object,
  donationCustomChargeTypeChanged: PropTypes.func.isRequired,
  debouncedBuildTicketReservation: PropTypes.func,
  feeRules: PropTypes.array.isRequired,
  donationTitle: PropTypes.string,
  donationCoverProcessingText: PropTypes.string,
};

export default ChooseCheckoutCategories;
