import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import Tippy from '@tippyjs/react';
import {DebounceInput} from 'react-debounce-input';

import CalendarOptions from '../../CalendarOptions/components/CalendarOptions';
import AnnouncementAlert from '../../AnnouncementAlert/components/AnnouncementAlert';
import DataTable from '../../DataTable/components/DataTable';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import momentTimezonePlugin from '@fullcalendar/moment-timezone'
import Popup from "reactjs-popup";
var moment = require('moment');

import CalendarEventConflictPopup from './CalendarEventConflictPopup';
import CheckAvailsPopup from './CheckAvailsPopup';
import EventForm from '../../EventForm/components/EventForm';
import CalendarSyncPopup from './CalendarSyncPopup';
import CalendarMenu from './CalendarMenu';

import $ from 'jquery';
require('datatables.net-bs4');

import {
  offsetDateForBrowser,
  setGonTimeZoneFromVenue
} from '../../../shared/timeZoneLogic';
import CSVImporter from '../../CSVImporter/CSVImporter';


const newContentStyle = (browser) => {
  var styles = {
    "border": "none",
    "borderRadius": "8px",
    "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
    "width": "594px",
    "maxWidth": ((browser.is.small || browser.is.extraSmall) ? "100vw" : "594px"),
    "marginTop": "10px",
    "zIndex": '200'
  }

  if (browser.greaterThan.small){
    styles["minWidth"] = "530px";
  }

  return styles;
}

const popupContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "200px",
  "padding": "0px"
}

const settingsContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "250px",
  "padding": "0px",
  "marginTop": "10px",
  "zIndex": "3000"
}

const calendarEventConflictsContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "650px",
  "padding": "30px"
}

const checkAvailsContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "698px",
  "padding": "18px 32px"
}

const calendarSyncContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "840px",
  "padding": "18px 0px"
}

const datesAreOnSameDay = (firstDate, secondDate) => {
  return (
    firstDate.getFullYear() === secondDate.getFullYear()
      && firstDate.getMonth() === secondDate.getMonth()
      && firstDate.getDate() === secondDate.getDate()
  );
};

const getAvailableDates = (events, checkAvailsSettings, checkAvailsDates, defaultTopHold) => {
  var foundDates = [];

  if(events === null){
    return foundDates;
  }

  if(checkAvailsDates.length !== 2){
    return foundDates;
  }

  var notCanceledEvents = events
    .filter((e) => e.classification !== "canceled");

  var startDate = checkAvailsDates[0];
  var endDate = checkAvailsDates[1];
  var currentDate = new Date(startDate);

  while(currentDate <= endDate){
    var isAvailable = (
      checkAvailsSettings.onDaysOfWeek.includes(currentDate.getDay())
        && (
          !checkAvailsSettings.noConfirms
            || notCanceledEvents
                .filter((e) => e.type === "Confirm")
                .filter((e) => {
                  return e.date_range
                    .some((d) => datesAreOnSameDay(new Date(d), currentDate))
                }).length === 0
        )
        && (
          !checkAvailsSettings.noHolds
            || notCanceledEvents
                .filter((e) => e.type === "Hold")
                .filter((e) => {
                  return e.date_range
                    .some((d) => datesAreOnSameDay(new Date(d), currentDate))
                }).length === 0
        )
    );

    if(isAvailable){
      var availablePosition = null;

      var takenPositions =
        notCanceledEvents
          .filter((e) => {
            return e.date_range
              .some((d) => datesAreOnSameDay(new Date(d), currentDate));
          })
          .map(e => e.position)
          .sort();

      // Find the first available position
      for(var i = defaultTopHold; i <= 20; i++){
        if(!takenPositions.includes(i)){
          availablePosition = i;
          break;
        }
      }

      foundDates.push({
        date: new Date(currentDate),
        position: availablePosition
      });
    }

    var nextDate = currentDate.setDate(currentDate.getDate() + 1);
    currentDate = new Date(nextDate);
  }

  return foundDates;
}

const HoldEvent = ({
  info,
  eventIconPath,
  eventColor
}) => {
  return (
    <Tippy className="calendar-view-tippy"
            content={
              <div className='row' style={{width: "232px"}}>
                <div className='col-12' style={{"fontSize": "10px"}}>
                  <div className='d-flex align-items-center'
                        style={{"marginBottom": "2px"}}>
                    <img src={eventIconPath(info.event.extendedProps, false)}
                          style={{width: "13px", marginRight: "4px"}} />
                    <span style={{"fontSize": "12px", "fontWeight": "bold"}}>
                      {info.event.extendedProps.titlecased_classification}
                    </span>
                  </div>
                  <p className='mb-0 text-truncate'
                      style={{
                        marginTop: "8px",
                        fontSize: "14px",
                        fontWeight: "bold",
                        lineHeight: "20px"
                      }}>
                    <strong>{info.event.title}</strong>
                  </p>
                  <p className='mb-0'
                      style={{
                        color: "#D5D7DA",
                        marginTop: "3px",
                        fontSize: "12px",
                        lineHeight: "18px"
                      }}>
                    {info.event.extendedProps.human_short_date}
                  </p>
                  <p className='mb-0'
                      style={{
                        color: "#D5D7DA",
                        fontSize: "12px",
                        lineHeight: "18px"
                      }}>
                    {info.event.extendedProps.venue_name}
                  </p>
                </div>
              </div>
            }>
      <div className='d-flex align-items-center w-100 h-100'
            style={{
              opacity: info.event.extendedProps.classification  === "past" ? "0.5" : "1",
            }}>
        <div style={{
                marginRight: "4px",
                padding: "2px 3px",
                borderRadius: "4px",
                border: "1px solid " + eventColor(info.event.extendedProps),
                fontSize: "10px",
                fontWeight: "bold",
                color: "#333"
              }}>
          {"H" + info.event.extendedProps.position}
        </div>
        <div className="flex-grow-1 d-flex align-items-center"
              style={{minWidth: '0'}}>
          <span className="fc-event-first-line mb-0 d-inline-block text-truncate"
              style={{
                maxWidth: "100%",
                marginRight: "0px"
              }}
              title={info.event.title}>
            {info.event.title}
          </span>
        </div>
        <img src={eventIconPath(info.event.extendedProps, true)}
              style={{
                width: "12px",
                minWidth: "12px",
                marginLeft: "3px"
              }} />
      </div>
    </Tippy>
  );
};

const ConfirmEvent = ({
  info,
  eventIconPath,
  eventColor
}) => {
  const hexToRGB = (hex) => {
    hex = hex.replace(/^#/, '');

    let bigint = parseInt(hex, 16);
    let r, g, b;

    if (hex.length === 3) {
      r = (bigint >> 8 & 0xF) * 17;
      g = (bigint >> 4 & 0xF) * 17;
      b = (bigint & 0xF) * 17;
    } else {
      r = (bigint >> 16) & 255;
      g = (bigint >> 8) & 255;
      b = bigint & 255;
    }

    return {
      r: r,
      g: g,
      b: b
    };
  }

  const formatTimestamp = (timestamp, monthFormat) => {
    const date = new Date(timestamp);

    const formattedDate = date.toLocaleDateString('en-US', {
      month: monthFormat,
      day: 'numeric',
      year: 'numeric',
    });

    let formattedTime = date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    }).toLowerCase();

    formattedTime = formattedTime.replace(/\s([ap]m)/, '$1');
    return `${formattedDate} - ${formattedTime}`;
  }

  const hexColor = eventColor(info.event.extendedProps);
  const rgbColor = hexToRGB(hexColor);

  const showActivities = info.event.extendedProps.calendar_tooltip_show_activities;
  const announceShowActivity = showActivities.find((sa) => sa.name === "Announce");
  const preSaleShowActivity = showActivities.find((sa) => sa.name === "Venue Pre-Sale");
  const onSaleShowActivity = showActivities.find((sa) => sa.name === "Public On-Sale");
  const startShowActivity = showActivities.find((sa) => sa.name === "Event Start");

  return (
    <Tippy className="calendar-view-tippy"
            content={
              <div className='form-row' style={{width: "232px"}}>
                <div className='col-12' style={{"fontSize": "10px"}}>
                  <div className='d-flex align-items-center'
                        style={{"marginBottom": "2px"}}>
                    <img src={eventIconPath({classification: "confirm"}, false)}
                          style={{width: "14px", marginRight: "4px"}} />
                    <span style={{"fontSize": "12px", "fontWeight": "bold"}}>
                      Confirm
                    </span>
                  </div>
                  <p className='mb-0 text-truncate'
                      style={{
                        marginTop: "8px",
                        fontSize: "14px",
                        fontWeight: "bold",
                        lineHeight: "20px"
                      }}>
                    <strong>{info.event.title}</strong>
                  </p>
                  <p className='mb-0'
                      style={{
                        color: "#D5D7DA",
                        marginTop: "3px",
                        fontSize: "12px",
                        lineHeight: "18px"
                      }}>
                    {startShowActivity && startShowActivity.start_time && startShowActivity.set ? (
                      formatTimestamp(startShowActivity.start_time, "long")
                    ) : (
                      <span>Not set</span>
                    )}
                  </p>
                  <p className='mb-0'
                      style={{
                        color: "#D5D7DA",
                        fontSize: "12px",
                        lineHeight: "18px"
                      }}>
                    {info.event.extendedProps.venue_name}
                  </p>
                </div>
                <div className='col-12'
                      style={{
                        "paddingTop": "10px",
                        "marginTop": "10px",
                        "borderTop": "1px solid #535862"
                      }}>
                  <div className='form-row'>
                    <div className='col-12 d-flex align-items-center' style={{marginTop: "3px"}}>
                      <img src={eventIconPath({classification: "announced"}, false)}
                          style={{width: "14px", marginRight: "7px"}} />
                      <p className='mb-0' style={{"fontSize": "12px", "fontWeight": "600"}}>
                        Announce
                      </p>
                      <p className='mb-0 flex-grow-1 text-right' style={{"fontSize": "12px"}}>
                        {announceShowActivity && announceShowActivity.start_time && announceShowActivity.set ? (
                          formatTimestamp(announceShowActivity.start_time, "short")
                        ) : (
                          <span>Not set</span>
                        )}
                      </p>
                    </div>
                    <div className='col-12 d-flex align-items-center' style={{marginTop: "7px"}}>
                      <img src={eventIconPath({classification: "pre_sale"}, false)}
                          style={{width: "14px", marginRight: "7px"}} />
                      <p className='mb-0' style={{"fontSize": "12px", "fontWeight": "600"}}>
                        Pre-sale
                      </p>
                      <p className='mb-0 flex-grow-1 text-right' style={{"fontSize": "12px"}}>
                        {preSaleShowActivity && preSaleShowActivity.start_time && preSaleShowActivity.set ? (
                          formatTimestamp(preSaleShowActivity.start_time, "short")
                        ) : (
                          <span>Not set</span>
                        )}
                      </p>
                    </div>
                    <div className='col-12 d-flex align-items-center' style={{marginTop: "7px", "marginBottom": "6px"}}>
                      <img src={eventIconPath({classification: "on_sale"}, false)}
                          style={{width: "14px", marginRight: "7px"}} />
                      <p className='mb-0' style={{"fontSize": "12px", "fontWeight": "600"}}>
                        On sale
                      </p>
                      <p className='mb-0 flex-grow-1 text-right' style={{"fontSize": "12px"}}>
                        {onSaleShowActivity && onSaleShowActivity.start_time && onSaleShowActivity.set ? (
                          formatTimestamp(onSaleShowActivity.start_time, "short")
                        ) : (
                          <span>Not set</span>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            }>
      <div className='d-flex align-items-start justify-content-start w-100 h-100'
            style={{
              opacity: (info.event.extendedProps.classification  === "past" ? "0.5" : "1"),
              backgroundColor: (info.event.extendedProps.classification === "confirm" ? `rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, 0.1)` : "white"),
              padding: "4px"
            }}>
        <div className="align-self-stretch"
              style={{
                width: "4px",
                minWidth: "4px",
                marginRight: "4px",
                borderRadius: "2px",
                background: hexColor
              }}>
        </div>
        <div className="flex-grow-1" style={{minWidth: '0'}}>
          <span className="fc-event-first-line mb-0 d-inline-block text-truncate"
              style={{maxWidth: "100%", marginTop: "-2px"}}
              title={info.event.title}>
            {info.event.title}
          </span>
          <p className='fc-event-second-line mb-0 text-truncate'>
            {info.timeText}
          </p>
        </div>
        <img src={eventIconPath(info.event.extendedProps, true)}
              style={{
                width: "16px",
                minWidth: "16px",
                marginLeft: "3px",
                marginTop: "4px"
              }} />
      </div>
    </Tippy>
  );
};

const CalendarViewV2 = ({
  browser,
  csrfToken,
  user,
  team,
  events,
  venuesOptions,
  venueSelected,
  venueOwnerships,
  updateSelectedVenue,
  submitNewEventForm,
  onInit,
  fetchTeamVenueConflicts,
  calendarEventConflicts,
  calendarEventConflictsModalOpen,
  closeCalendarEventConflictsModal,
  confirmTeamVenueConflicts,
  saveUserCalendarStartDay,
  calendarEventConflictMergeCheckboxClicked,
  holdGroup,
  confirm,
  confirmChanged,
  calendarEventTitle,
  updateCalendarEventTitle,
  calendarEventArtists,
  updateCalendarEventArtists,
  calendarEventType,
  updateCalendarEventType,
  calendarEventTicketForecast,
  calendarEventTicketForecastChanged,
  calendarEventNoteEditorState,
  updateCalendarEventNoteEditorState,
  calendarEventErrors,
  calendarEventFormClosed,
  userChangedCalendarEventTitle,
  onSaleEnabled,
  preSaleEnabled,
  announcedEnabled,
  confirmedEnabled,
  holdsEnabled,
  pastEventsEnabled,
  cancelledEventsEnabled,
  toggleOnSaleEnabled,
  togglePreSaleEnabled,
  toggleAnnouncedEnabled,
  toggleConfirmedEnabled,
  toggleHoldsEnabled,
  togglePastEventsEnabled,
  toggleCancelledEventsEnabled,
  holdGroupCalendarDateClicked,
  holdGroupDates,
  holdGroupHoldPositionUpdated,
  holdGroupHoldRemoved,
  confirmStartDateUpdated,
  confirmStartTimeUpdated,
  fetchHoldAvailability,
  calendarStartDate,
  calendarStartDateUpdated,
  dataTableTitle,
  dataTableDataSource,
  dataTableOrder,
  dataTableColumns,
  dataTableFilters,
  reloadDataTable,
  dataTableEl,
  dataTableElChanged,
  selectedPromoter,
  selectedPromoterChanged,
  buyers,
  buyersChanged,
  promoters,
  promotersChanged,
  selectedBuyer,
  selectedBuyerChanged,
  holdGroupDeletion,
  holdGroupDeletionChanged,
  holdGroupDeletionReasonChanged,
  holdGroupDeletionExplanationChanged,
  holdGroupDeletionFollowUpChanged,
  holdGroupDeletionFollowUpAtChanged,
  deleteHoldGroup,
  searchTerm,
  searchTermChanged,
  currentTeamMembership,
  saveTeamMembershipCalendarState,
  submittingForm,
  doorsTimeDefault,
  eventEndTimeDefault,
  userChangedDoorsTime,
  userChangedDoorsTimeChanged,
  userChangedEventEndTime,
  userChangedEventEndTimeChanged,
  applyTimeDefaults,
  checkAvailsIsOpen,
  checkAvailsIsOpenChanged,
  checkAvailsSettings,
  checkAvailsSettingsChanged,
  calendarSyncIsOpen,
  calendarSyncIsOpenChanged,
  checkAvailsDates,
  checkAvailsDatesChanged,
  checkAvailsCalendarEvents,
  checkAvailsCalendarEventsChanged,
  fetchCheckAvailsCalendarEvents,
  checkAvailsLoading,
  holdGroupChanged,
  fetchCalendarEventsForSelectedVenues,
  dataTableFiltersChanged,
  currentTeamMembershipChanged,
  venueOwnershipsChanged
}) => {
  const canSelectMultipleVenues = team.package.name !== "Opener";
  const [selectedVenues, setSelectedVenues] = useState(
    venuesOptions.filter((vo) =>
      currentTeamMembership.calendar_venue_ids.includes(vo.id)
    ).map((vo) => {
      return { value: vo.id, label: vo.name };
    })
  );

  const [selectedTags, setSelectedTags] = useState([]);
  const [recordCount, setRecordCount] = useState(0);

  // Some situations require us to have a default venue, such
  // as if the user clicks on the calendar to open the event form.
  useEffect(() => {
    if(selectedVenues.length === 1){
      updateSelectedVenue(venuesOptions, selectedVenues[0]);
    } else {
      updateSelectedVenue(venuesOptions, {value: venuesOptions[0].id});
    }
  }, [selectedVenues])

  useEffect(() => {
    if(venueSelected && venueSelected.id){
      setGonTimeZoneFromVenue(venueOwnerships, venueSelected.id);
    }
  }, [venueSelected])

  useEffect(() => {
    onInit(team, selectedVenues, selectedTags, user, dataTableFilters);
    const params = (new URL(document.location)).searchParams;
    const artist = params.get("artist");

    if (artist) {
      const decodedArtist = decodeURIComponent(window.location.search.split("=")[1])
      searchTermChanged(decodedArtist)
    }
  }, [])

  useEffect(() => {
    checkAvailsCalendarEventsChanged(null);

    if(checkAvailsDates.length === 2){
      fetchCheckAvailsCalendarEvents(team, selectedVenues, checkAvailsDates);
    }
  }, [
    checkAvailsDates,
    checkAvailsCalendarEventsChanged,
    fetchCheckAvailsCalendarEvents,
    selectedVenues
  ])

  useEffect(() => {
    if(currentTeamMembership.calendar_view === "list"){
      return false;
    }

    if(browser.is.small || browser.is.extraSmall){
      var updated = {
        ...currentTeamMembership,
        calendar_view: "list"
      }

      currentTeamMembershipChanged(updated);
      saveTeamMembershipCalendarState(csrfToken, team, currentTeamMembership, {
        calendar_view: updated.calendar_view
      });
    }
  }, [browser.is.small, browser.is.extraSmall])

  useEffect(() => {
    if(browser.lessThan.large){
      var updated = {
        ...currentTeamMembership,
        show_calendar_menu: false
      }

      currentTeamMembershipChanged(updated);
      saveTeamMembershipCalendarState(csrfToken, team, currentTeamMembership, {
        show_calendar_menu: updated.show_calendar_menu
      });
    }
  }, [browser.lessThan.large])

  useEffect(() => {
    setRecordCount(0);

    if (Object.keys(dataTableEl).length > 0) {
      const table = $(dataTableEl).DataTable();
      table.on('draw.dt', function() {
        setRecordCount(table.page.info().recordsTotal);
      });
    }
  }, [dataTableEl, currentTeamMembership.calendar_view]);

  const popupRef = useRef(null);
  const calendarRef = useRef(null);

  const eventColor = (event) => {
    var venueOwnership = venueOwnerships
      .find((vo) => vo.id === event.venue_ownership_id);

    if(venueOwnership){
      return venueOwnership.calendar_color_hex;
    } else {
      return "#FF595E";
    }
  }

  const eventIconPath = (event, dark) => {
    var path = "";

    switch (event.classification) {
      case "hold":
        path = (dark ? "icons/hold.svg" : "icons/hold-white.svg");
        break;
      case "confirm":
        path = (dark ? "icons/calendar-check.svg" : "icons/calendar-check-white.svg");
        break;
      case "announced":
        path = (dark ? "icons/announcement.svg" : "icons/announcement-white.svg");
        break;
      case "on_sale":
        path = (dark ? "icons/ticket.svg" : "icons/ticket-white.svg");
        break;
      case "canceled":
        path = (dark ? "icons/x-square.svg" : "icons/x-square-white.svg");
        break;
      case "past":
        path = (dark ? "icons/past.svg" : "icons/past-white.svg");
        break;
      case "pre_sale":
        path = (dark ? "icons/pre-sale.svg" : "icons/pre-sale-white.svg");
        break;
    }

    return path;
  }

  return (
    <div className="CalendarViewV2App">
      <ToastContainer />
      <AnnouncementAlert csrfToken={csrfToken}
                          user={user}
                          team={team} />
      <div className="form-row flex-nowrap">
        {currentTeamMembership.show_calendar_menu ? (
          <div className='col-auto'>
            <CalendarMenu team={team}
                          user={user}
                          csrfToken={csrfToken}
                          calendarStartDate={calendarStartDate}
                          calendarRef={calendarRef.current || {}}
                          saveTeamMembershipCalendarState={saveTeamMembershipCalendarState}
                          currentTeamMembershipChanged={currentTeamMembershipChanged}
                          venuesOptions={venuesOptions}
                          selectedVenues={selectedVenues}
                          setSelectedVenues={setSelectedVenues}
                          fetchCalendarEventsForSelectedVenues={fetchCalendarEventsForSelectedVenues}
                          selectedTags={selectedTags}
                          setSelectedTags={setSelectedTags}
                          fetchTeamVenueConflicts={fetchTeamVenueConflicts}
                          dataTableFilters={dataTableFilters}
                          dataTableFiltersChanged={dataTableFiltersChanged}
                          venueOwnerships={venueOwnerships}
                          canSelectMultipleVenues={canSelectMultipleVenues}
                          venueOwnershipsChanged={venueOwnershipsChanged}
                          onSaleEnabled={onSaleEnabled}
                          announcedEnabled={announcedEnabled}
                          confirmedEnabled={confirmedEnabled}
                          holdsEnabled={holdsEnabled}
                          pastEventsEnabled={pastEventsEnabled}
                          cancelledEventsEnabled={cancelledEventsEnabled}
                          preSaleEnabled={preSaleEnabled}
                          toggleOnSaleEnabled={toggleOnSaleEnabled}
                          toggleAnnouncedEnabled={toggleAnnouncedEnabled}
                          toggleConfirmedEnabled={toggleConfirmedEnabled}
                          toggleHoldsEnabled={toggleHoldsEnabled}
                          togglePastEventsEnabled={togglePastEventsEnabled}
                          toggleCancelledEventsEnabled={toggleCancelledEventsEnabled}
                          togglePreSaleEnabled={togglePreSaleEnabled}
                          searchTermChanged={searchTermChanged}
                          dataTableEl={dataTableEl}
                          browser={browser}
                          currentTeamMembership={currentTeamMembership} />
          </div>
        ) : null}
        <div className='col'>
          <div>
            <div className='d-flex flex-wrap' style={{"margin": "8px 0px 0px 0px"}}>
              <div className='flex-fill pl-0 d-flex align-items-center justify-content-start'
                    style={{"marginBottom": "8px"}}>
                <a href="#"
                    onClick={
                      (e) => {
                        e.preventDefault();

                        var updated = {
                          ...currentTeamMembership,
                          show_calendar_menu: !currentTeamMembership.show_calendar_menu
                        }

                        currentTeamMembershipChanged(updated);
                        saveTeamMembershipCalendarState(csrfToken, team, currentTeamMembership, {
                          show_calendar_menu: updated.show_calendar_menu
                        });
                      }
                    }
                    style={{
                      "marginRight": "8px"
                    }}
                    className='btn btn-calendar small font-weight-bold'>
                  <i className="fas fa-sliders-h"></i>
                </a>
                {calendarRef.current ? (
                  <>
                    <a href="#"
                        onClick={
                          (e) => {
                            e.preventDefault();
                            calendarRef.current.getApi().today();
                          }
                        }
                        style={{
                          "fontSize": "14px",
                          "fontWeight": "600",
                          "marginRight": "8px"
                        }}
                        className='btn btn-calendar small'>
                      Today
                    </a>
                    <a href="#"
                        onClick={
                          (e) => {
                            e.preventDefault();
                            calendarRef.current.getApi().prev();
                          }
                        }
                        style={{
                          "fontSize": "14px",
                          "fontWeight": "600",
                          "marginRight": "8px"
                        }}
                        className='btn btn-calendar small'>
                      <i className="fas fa-chevron-left"></i>
                    </a>
                    <a href="#"
                        onClick={
                          (e) => {
                            e.preventDefault();
                            calendarRef.current.getApi().next();
                          }
                        }
                        style={{
                          "fontSize": "14px",
                          "fontWeight": "600",
                          "marginRight": "8px"
                        }}
                        className='btn btn-calendar small'>
                      <i className="fas fa-chevron-right"></i>
                    </a>
                    <p className='mb-0 text-truncate'
                        style={{
                          "fontSize": "21px",
                          "fontWeight": "bold"
                        }}>
                      {calendarRef.current.getApi().getCurrentData().viewTitle}
                    </p>
                  </>
                ) : (
                  <>
                    <p className='mb-0'
                        style={{
                          "fontSize": "18px",
                          "fontWeight": "600",
                          "marginRight": "8px"
                        }}>
                      My events
                    </p>
                    {Object.keys(dataTableEl).length > 0 ? (
                      <div style={{
                        "background": "#F6FAFE",
                        "border": "1px solid #DFF0FB",
                        "borderRadius": "9px",
                        "padding": "2px 8px",
                        "color": "#136395"
                      }}>
                        <p className='mb-0 small'>
                          {recordCount.toLocaleString()}
                        </p>
                      </div>
                    ) : null}
                  </>
                )}
              </div>
              <div className="flex-fill pr-0 d-flex align-items-center justify-content-end"
                    style={{"marginBottom": "8px"}}>
                {user.role.name !== "Promoter" ? (
                  <form className='form-inline' style={{"marginRight": "8px"}}>
                    <div className="input-group">
                      <DebounceInput type="search"
                            debounceTimeout={400}
                            className="form-control form-control-sm"
                            style={{
                              fontSize: "14px",
                              color: "#B3B3B3",
                              borderLeft: "1px solid #ECECEC",
                              borderRight: "none",
                              borderTop: "1px solid #ECECEC",
                              borderBottom: "1px solid #ECECEC",
                              borderTopLeftRadius: "8px",
                              borderBottomLeftRadius: "8px",
                              lineHeight: "21px",
                              paddingTop: "5.6px",
                              paddingBottom: "5.6px",
                              height: "34.19px"
                            }}
                            placeholder={"Search events..."}
                            value={searchTerm}
                            onChange={
                              (e) => {
                                searchTermChanged(e.target.value);
                              }
                            }
                            onKeyPress={
                              (e) => {
                                if (e.key === 'Enter') e.preventDefault();
                              }
                            }
                            aria-label="Search"
                            aria-describedby="table-search-addon" />
                      <div className="input-group-append">
                        <span className="input-group-text bg-white text-dark"
                              style={{
                                borderLeft: "none",
                                borderRight: "1px solid #ECECEC",
                                borderTop: "1px solid #ECECEC",
                                borderBottom: "1px solid #ECECEC",
                                borderTopRightRadius: "8px",
                                borderBottomRightRadius: "8px",
                              }}
                              id="table-search-addon">
                          <i className="fa fa-search"></i>
                        </span>
                      </div>
                    </div>
                  </form>
                ) : null}
                <CalendarOptions csrfToken={csrfToken}
                            user={user}
                            team={team}
                            saveUserCalendarStartDay={saveUserCalendarStartDay}
                            currentTeamMembership={currentTeamMembership}
                            checkAvailsIsOpenChanged={checkAvailsIsOpenChanged}
                            calendarSyncIsOpenChanged={calendarSyncIsOpenChanged}
                            fetchCalendarEventsForSelectedVenues={fetchCalendarEventsForSelectedVenues}
                            selectedVenues={selectedVenues}
                            showExportCsv={currentTeamMembership.calendar_view === "list"}
                            dataTableEl={dataTableEl}
                            dataTableFilters={dataTableFilters}>
                  <a href="#"
                      onClick={(e) => {e.preventDefault();}}
                      style={{"marginRight": "8px"}}
                      className='btn btn-calendar small font-weight-bold'>
                    Options
                  </a>
                </CalendarOptions>
                {user.can_create_calendar_event || user.can_create_hold_group ? (
                  <Popup arrow={false}
                        ref={popupRef}
                        offsetY={5}
                        offsetX={((browser.is.small || browser.is.extraSmall) ? 15 : 0)}
                        position="bottom right"
                        contentStyle={newContentStyle(browser)}
                        overlayStyle={{ zIndex: "199" }}
                        closeOnDocumentClick={false}
                        onOpen={
                          (e) => {
                            e.preventDefault();
                          }
                        }
                        onClose={
                          () => {
                            calendarEventFormClosed(() => {
                              reloadDataTable();
                            });
                          }
                        }
                        trigger={open => (
                    <a href="#"
                        onClick={(e) => {e.preventDefault();}}
                        style={{
                          "fontWeight": "600",
                          "borderRadius": "8px",
                          "borderWidth": "1px",
                          "paddingTop": "5.6px",
                          "paddingBottom": "5.6px",
                          "fontSize": "14px",
                          "lineHeight": "21px",
                          "boxShadow": "0px 0px 0px 1px rgba(10, 13, 18, 0.18) inset, 0px -2px 0px 0px rgba(10, 13, 18, 0.05) inset, 0px 1px 2px 0px rgba(10, 13, 18, 0.05)"
                        }}
                        className='btn btn-primary small text-nowrap'>
                      <i className="fa fa-plus"></i>
                      &nbsp;
                      New Event
                    </a>
                  )} >
                    {close => (
                      <div className='text-left'>
                        <EventForm formTitle={"New Event"}
                                  formButtonLabel={"Create Event"}
                                  csrfToken={csrfToken}
                                  currentUser={user}
                                  team={team}
                                  venuesOptions={venuesOptions}
                                  venueSelected={venueSelected}
                                  submitEventForm={submitNewEventForm}
                                  onSuccess={(url) => {window.location.href = url;}}
                                  updateSelectedVenue={updateSelectedVenue}
                                  onVenueChange={
                                    (selected) => {
                                      saveTeamMembershipCalendarState(csrfToken, team, currentTeamMembership, {
                                        calendar_venue_id: selected.value
                                      });
                                    }
                                  }
                                  onClose={
                                    () => {
                                      close();
                                    }
                                  }
                                  holdGroup={holdGroup}
                                  confirm={confirm}
                                  confirmChanged={confirmChanged}
                                  calendarEventFormClosed={calendarEventFormClosed}
                                  calendarEventTitle={calendarEventTitle}
                                  updateCalendarEventTitle={updateCalendarEventTitle}
                                  calendarEventArtists={calendarEventArtists}
                                  updateCalendarEventArtists={updateCalendarEventArtists}
                                  calendarEventType={calendarEventType}
                                  updateCalendarEventType={updateCalendarEventType}
                                  calendarEventTicketForecast={calendarEventTicketForecast}
                                  calendarEventTicketForecastChanged={calendarEventTicketForecastChanged}
                                  calendarEventNoteEditorState={calendarEventNoteEditorState}
                                  updateCalendarEventNoteEditorState={updateCalendarEventNoteEditorState}
                                  userChangedCalendarEventTitle={userChangedCalendarEventTitle}
                                  holdGroupCalendarDateClicked={holdGroupCalendarDateClicked}
                                  holdGroupDates={holdGroupDates}
                                  holdGroupHoldPositionUpdated={holdGroupHoldPositionUpdated}
                                  holdGroupHoldRemoved={holdGroupHoldRemoved}
                                  confirmStartDateUpdated={confirmStartDateUpdated}
                                  confirmStartTimeUpdated={confirmStartTimeUpdated}
                                  fetchHoldAvailability={fetchHoldAvailability}
                                  calendarRef={calendarRef.current || {}}
                                  calendarStartDate={calendarStartDate}
                                  calendarStartDateUpdated={calendarStartDateUpdated}
                                  selectedPromoter={selectedPromoter}
                                  selectedPromoterChanged={selectedPromoterChanged}
                                  buyers={buyers}
                                  buyersChanged={buyersChanged}
                                  promoters={promoters}
                                  promotersChanged={promotersChanged}
                                  selectedBuyer={selectedBuyer}
                                  selectedBuyerChanged={selectedBuyerChanged}
                                  holdGroupDeletion={holdGroupDeletion}
                                  holdGroupDeletionChanged={holdGroupDeletionChanged}
                                  holdGroupDeletionReasonChanged={holdGroupDeletionReasonChanged}
                                  holdGroupDeletionExplanationChanged={holdGroupDeletionExplanationChanged}
                                  holdGroupDeletionFollowUpChanged={holdGroupDeletionFollowUpChanged}
                                  holdGroupDeletionFollowUpAtChanged={holdGroupDeletionFollowUpAtChanged}
                                  deleteHoldGroup={deleteHoldGroup}
                                  submittingForm={submittingForm}
                                  calendarEventErrors={calendarEventErrors}
                                  doorsTimeDefault={doorsTimeDefault}
                                  eventEndTimeDefault={eventEndTimeDefault}
                                  userChangedDoorsTime={userChangedDoorsTime}
                                  userChangedDoorsTimeChanged={userChangedDoorsTimeChanged}
                                  userChangedEventEndTime={userChangedEventEndTime}
                                  userChangedEventEndTimeChanged={userChangedEventEndTimeChanged}
                                  holdGroupChanged={holdGroupChanged}
                                  applyTimeDefaults={applyTimeDefaults} />
                      </div>
                    )}
                  </Popup>
                ) : null}
              </div>
            </div>
            {(currentTeamMembership.calendar_view === "calendar" || currentTeamMembership.calendar_view === "calendar_weekly") ? (
              <div>
                <FullCalendar buttonText={{today: "Today"}}
                              ref={calendarRef}
                              dateClick={
                                (info) => {
                                  if(popupRef.current.state.isOpen){
                                    return false;
                                  };

                                  popupRef.current.TriggerEl.click();

                                  var date = offsetDateForBrowser(info.date);
                                  date.setHours(12, 0, 0, 0);

                                  // Same as onDayClick in EventForm
                                  holdGroupCalendarDateClicked(date, undefined);
                                  fetchHoldAvailability(team, venueSelected.id, date);
                                }
                              }
                              initialDate={currentTeamMembership.calendar_starts_at}
                              datesSet={
                                (info) => {
                                  var date = info.view.currentStart;

                                  // Adding 2 days bypasses any issue selecting the wrong date
                                  // due to timezone differences, while also ensuring the date
                                  // is within the same week in the week view
                                  var adjustedDate = moment(date).add('days', 2);

                                  calendarStartDateUpdated(adjustedDate.toDate());
                                  saveTeamMembershipCalendarState(csrfToken, team, currentTeamMembership, {
                                    calendar_starts_at: adjustedDate
                                  });

                                  // Date boxes were small until you resized the window
                                  window.dispatchEvent(new Event('resize'));

                                  fetchCalendarEventsForSelectedVenues(team, selectedVenues, selectedTags);
                                }
                              }
                              firstDay={user.calendar_start_day}
                              headerToolbar={{
                                start: '',
                                center: '',
                                end:  ''
                              }}
                              defaultTimedEventDuration={'00:00:01'}
                              events={
                                (info, successCallback, failureCallback) => {
                                  if(!onSaleEnabled){
                                    events = events.filter((event) => event.classification !== "on_sale")
                                  }

                                  if(!preSaleEnabled){
                                    events = events.filter((event) => event.classification !== "pre_sale")
                                  }

                                  if(!announcedEnabled){
                                    events = events.filter((event) => event.classification !== "announced")
                                  }

                                  if(!confirmedEnabled){
                                    events = events.filter((event) => event.classification !== "confirm")
                                  }

                                  if(!holdsEnabled){
                                    events = events.filter((event) => event.classification !== "hold")
                                  }

                                  if(!pastEventsEnabled){
                                    events = events.filter((event) => event.classification !== "past")
                                  }

                                  if(!cancelledEventsEnabled){
                                    events = events.filter((event) => event.classification !== "canceled")
                                  }

                                  successCallback(events);
                                }
                              }
                              eventClick={
                                (eventInfo) => {
                                  if(!eventInfo.event.extendedProps.authorized){
                                    eventInfo.jsEvent.preventDefault();
                                    return false;
                                  }
                                }
                              }
                              eventOrder={
                                ["type_position", "position", "start"]
                              }
                              eventTimeFormat={{
                                hour: 'numeric',
                                minute: '2-digit',
                                meridiem: 'short'
                              }}
                              eventClassNames={
                                (info) => {
                                  var extraClasses = [];
                                  extraClasses.push("fc-event-" + info.event.extendedProps.type);

                                  return extraClasses.join(" ");
                                }
                              }
                              eventContent= {
                                (info) => (
                                  info.event.extendedProps.type === "Hold" ? (
                                    <HoldEvent info={info}
                                                eventColor={eventColor}
                                                eventIconPath={eventIconPath} />
                                  ) : (
                                    <ConfirmEvent info={info}
                                                eventColor={eventColor}
                                                eventIconPath={eventIconPath} />
                                  )
                                )
                              }
                              eventDidMount={(info) => {
                                if(info.event.extendedProps.classification === "confirm"){
                                  const hexColor = eventColor(info.event.extendedProps);
                                  info.el.style.border = `1px solid ${hexColor}`;
                                }
                              }}
                              timeZone={
                                selectedVenues.length === 1 ? gon.timeZone : "local"
                              }
                              initialView={(currentTeamMembership.calendar_view === "calendar" ? "dayGridMonth" : "timeGridWeek")}
                              eventMinHeight={35}
                              slotEventOverlap={false}
                              eventMaxStack={2}
                              plugins={[
                                dayGridPlugin,
                                timeGridPlugin,
                                momentTimezonePlugin,
                                interactionPlugin
                              ]} />
              </div>
            ) : (
              <div className='calendar-view-datatable'>
                <DataTable title={dataTableTitle}
                          dataSource={dataTableDataSource}
                          columns={dataTableColumns}
                          reducedFilters={dataTableFilters}
                          searchedSearchTerm={searchTerm}
                          colGroup={
                            <colgroup>
                              <col span={1} style={{"width": "35%"}} />
                              <col span={1} style={{"width": "20%"}} />
                              <col span={1} style={{"width": "10%"}} />
                              <col span={1} style={{"width": "20%"}} />
                              <col span={1} style={{"width": "15%"}} />
                            </colgroup>
                          }
                          onTableElementSet={
                            (el) => {
                              dataTableElChanged(el);
                            }
                          }
                          createdRow={
                            (row, data, dataIndex) => {
                              $(row).addClass('position-relative');
                            }
                          }
                          order={dataTableOrder} />
              </div>
            )}
            <Popup
              open={calendarEventConflictsModalOpen}
              contentStyle={calendarEventConflictsContentStyle}
              className={"popup-modal"}
              closeOnDocumentClick
              onClose={() => closeCalendarEventConflictsModal()}
            >
              <CalendarEventConflictPopup calendarEventConflicts={calendarEventConflicts}
                                          team={team}
                                          csrfToken={csrfToken}
                                          confirmTeamVenueConflicts={confirmTeamVenueConflicts}
                                          selectedVenues={selectedVenues}
                                          selectedTags={selectedTags}
                                          calendarEventConflictMergeCheckboxClicked={calendarEventConflictMergeCheckboxClicked}
                                          closeModal={closeCalendarEventConflictsModal} />
            </Popup>
            <Popup
              open={checkAvailsIsOpen}
              contentStyle={checkAvailsContentStyle}
              className={"popup-modal"}
              closeOnDocumentClick={true}
              onClose={
                () => {
                  checkAvailsIsOpenChanged(false);
                }
              }>
              <CheckAvailsPopup team={team}
                                checkAvailsIsOpenChanged={checkAvailsIsOpenChanged}
                                checkAvailsSettings={checkAvailsSettings}
                                checkAvailsDates={checkAvailsDates}
                                checkAvailsDatesChanged={checkAvailsDatesChanged}
                                checkAvailsSettingsChanged={checkAvailsSettingsChanged}
                                checkAvailsLoading={checkAvailsLoading}
                                csrfToken={csrfToken}
                                availableDates={getAvailableDates(checkAvailsCalendarEvents, checkAvailsSettings, checkAvailsDates, team.default_top_hold)}
                                currentTeamMembership={currentTeamMembership}
                                calendarRef={calendarRef.current || {}} />
            </Popup>
            <Popup
              open={calendarSyncIsOpen}
              contentStyle={calendarSyncContentStyle}
              className={"popup-modal"}
              closeOnDocumentClick={true}
              onClose={() => { calendarSyncIsOpenChanged(false); }}
            >
              <CalendarSyncPopup
                team={team}
                venueOwnership={venueOwnerships.find((vo) => vo.venueable_id === venueSelected?.id)}
                calendarSyncIsOpenChanged={calendarSyncIsOpenChanged}
              />
            </Popup>
          </div>
        </div>
      </div>
    </div>
  )
};

CalendarViewV2.propTypes = {
  browser: PropTypes.object.isRequired,
  csrfToken: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  team: PropTypes.object.isRequired,
  venuesOptions: PropTypes.array,
  venueSelected: PropTypes.object,
  venueOwnerships: PropTypes.array,
  updateSelectedVenue: PropTypes.func.isRequired,
  events: PropTypes.array,
  submitNewEventForm: PropTypes.func.isRequired,
  onInit: PropTypes.func.isRequired,
  fetchTeamVenueConflicts: PropTypes.func.isRequired,
  calendarEventConflicts: PropTypes.array,
  calendarEventConflictsModalOpen: PropTypes.bool,
  closeCalendarEventConflictsModal: PropTypes.func.isRequired,
  confirmTeamVenueConflicts: PropTypes.func.isRequired,
  saveUserCalendarStartDay: PropTypes.func.isRequired,
  calendarEventConflictMergeCheckboxClicked: PropTypes.func.isRequired,
  holdGroup: PropTypes.object,
  confirm: PropTypes.object,
  confirmChanged: PropTypes.func.isRequired,
  calendarEventTitle: PropTypes.string,
  updateCalendarEventTitle: PropTypes.func.isRequired,
  calendarEventArtists: PropTypes.array,
  updateCalendarEventArtists: PropTypes.func.isRequired,
  calendarEventType: PropTypes.string,
  updateCalendarEventType: PropTypes.func.isRequired,
  calendarEventTicketForecast: PropTypes.number,
  calendarEventTicketForecastChanged: PropTypes.func.isRequired,
  calendarEventNoteEditorState: PropTypes.object,
  updateCalendarEventNoteEditorState: PropTypes.func.isRequired,
  calendarEventErrors: PropTypes.array,
  calendarEventFormClosed: PropTypes.func.isRequired,
  userChangedCalendarEventTitle: PropTypes.bool,
  onSaleEnabled: PropTypes.bool,
  preSaleEnabled: PropTypes.bool,
  announcedEnabled: PropTypes.bool,
  confirmedEnabled: PropTypes.bool,
  holdsEnabled: PropTypes.bool,
  pastEventsEnabled: PropTypes.bool,
  cancelledEventsEnabled: PropTypes.bool,
  toggleOnSaleEnabled: PropTypes.func.isRequired,
  togglePreSaleEnabled: PropTypes.func.isRequired,
  toggleAnnouncedEnabled: PropTypes.func.isRequired,
  toggleConfirmedEnabled: PropTypes.func.isRequired,
  toggleHoldsEnabled: PropTypes.func.isRequired,
  togglePastEventsEnabled: PropTypes.func.isRequired,
  toggleCancelledEventsEnabled: PropTypes.func.isRequired,
  holdGroupCalendarDateClicked: PropTypes.func.isRequired,
  holdGroupDates: PropTypes.array,
  holdGroupHoldPositionUpdated: PropTypes.func.isRequired,
  holdGroupHoldRemoved: PropTypes.func.isRequired,
  confirmStartDateUpdated: PropTypes.func.isRequired,
  confirmStartTimeUpdated: PropTypes.func.isRequired,
  fetchHoldAvailability: PropTypes.func.isRequired,
  calendarStartDate: PropTypes.instanceOf(Date),
  calendarStartDateUpdated: PropTypes.func.isRequired,
  dataTableTitle: PropTypes.string.isRequired,
  dataTableDataSource: PropTypes.string.isRequired,
  dataTableOrder: PropTypes.array,
  dataTableColumns: PropTypes.array.isRequired,
  dataTableFilters: PropTypes.object,
  reloadDataTable: PropTypes.func.isRequired,
  dataTableEl: PropTypes.object,
  dataTableElChanged: PropTypes.func.isRequired,
  selectedPromoter: PropTypes.object,
  selectedPromoterChanged: PropTypes.func.isRequired,
  buyers: PropTypes.array,
  buyersChanged: PropTypes.func.isRequired,
  promoters: PropTypes.array,
  promotersChanged: PropTypes.func.isRequired,
  selectedBuyer: PropTypes.object,
  selectedBuyerChanged: PropTypes.func.isRequired,
  holdGroupDeletion: PropTypes.object,
  holdGroupDeletionChanged: PropTypes.func.isRequired,
  holdGroupDeletionReasonChanged: PropTypes.func.isRequired,
  holdGroupDeletionExplanationChanged: PropTypes.func.isRequired,
  holdGroupDeletionFollowUpChanged: PropTypes.func.isRequired,
  holdGroupDeletionFollowUpAtChanged: PropTypes.func.isRequired,
  deleteHoldGroup: PropTypes.func.isRequired,
  searchTerm: PropTypes.string,
  searchTermChanged: PropTypes.func.isRequired,
  currentTeamMembership: PropTypes.object.isRequired,
  saveTeamMembershipCalendarState: PropTypes.func.isRequired,
  submittingForm: PropTypes.bool,
  doorsTimeDefault: PropTypes.object.isRequired,
  eventEndTimeDefault: PropTypes.object.isRequired,
  userChangedDoorsTime: PropTypes.bool,
  userChangedDoorsTimeChanged: PropTypes.func.isRequired,
  userChangedEventEndTime: PropTypes.bool,
  userChangedEventEndTimeChanged: PropTypes.func.isRequired,
  applyTimeDefaults: PropTypes.func.isRequired,
  checkAvailsIsOpen: PropTypes.bool,
  checkAvailsIsOpenChanged: PropTypes.func.isRequired,
  checkAvailsSettings: PropTypes.object,
  checkAvailsSettingsChanged: PropTypes.func.isRequired,
  calendarSyncIsOpen: PropTypes.bool,
  calendarSyncIsOpenChanged: PropTypes.func.isRequired,
  checkAvailsDates: PropTypes.array,
  checkAvailsDatesChanged: PropTypes.func.isRequired,
  checkAvailsCalendarEvents: PropTypes.array,
  checkAvailsCalendarEventsChanged: PropTypes.func.isRequired,
  fetchCheckAvailsCalendarEvents: PropTypes.func.isRequired,
  checkAvailsLoading: PropTypes.bool,
  holdGroupChanged: PropTypes.func.isRequired,
  fetchCalendarEventsForSelectedVenues: PropTypes.func.isRequired,
  dataTableFiltersChanged: PropTypes.func.isRequired,
  currentTeamMembershipChanged: PropTypes.func.isRequired,
  venueOwnershipsChanged: PropTypes.func.isRequired
};

export default CalendarViewV2;
