import React, { memo } from 'react';
import { Handle, Position } from '@xyflow/react';

import { useCustomCampaignShowContext } from "../CustomCampaignShowContainer";
import NodeErrorContainer from "../NodeErrorContainer";
import TriggerNodeBody from "./TriggerNodeBody";

const nodeStyle = {
  backgroundColor: "white",
  width: "fit-content",
  height: "fit-content",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: "5px 10px",
  color: "#333333",
  fontWeight: 600,
  borderRadius: "4px",
}

const headerStyle = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "8px",
  fontSize: "16px",
  width: "100%",
};

const bodyStyle = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "8px",
  fontSize: "16px",
  width: "100%",
  marginLeft: "34px",
};

export default memo(({ data }) => {
  const { handleClick } = data;
  const { selectedNode } = useCustomCampaignShowContext();

  const isSelected = selectedNode?.data?.internalId === data.internalId;

  return (
    <div style={{
      ...nodeStyle,
      outline: isSelected ? `2px solid ${data.color}` : `1px solid ${data.color}`,
      boxShadow: isSelected ? "0px 3px 6px 0px #0A0D121A" : "none",
    }} onClick={() => handleClick(data)}>
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={false}
        style={{visibility: "hidden"}}
      />
      <div style={headerStyle}>
        <div className="figma-text sm primary semibold">
          <i className={`${data.icon} figma-text sm quinary`} style={{ marginRight: "5px" }} />
          {data.title}
        </div>
        <NodeErrorContainer errors={data.workflowErrors} />
      </div>
      <div style={bodyStyle}>
        {
          data.type === "WorkflowNodes::TriggerNode" ? <TriggerNodeBody isSelected={isSelected} /> :
          null
        }
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        isConnectable={false}
        style={{visibility: "hidden"}}
      />
    </div>
  );
});
