import React, { memo } from 'react';
import { Handle, Position } from '@xyflow/react';

import { useCustomCampaignShowContext } from "../CustomCampaignShowContainer";

const dropZoneStyle = {
  backgroundColor: "transparent",
  width: "180px",
  height: "20px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "2px",
}

const nodeStyle = {
  backgroundColor: "#1982C4",
  width: "12px",
  height: "12px",
  borderRadius: "10px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  color: "white",
  fontSize: "8px",
}

export default memo(({ data }) => {
  const {
    targetDropZoneEdgeInternalId,
    setSelectedNode,
    setBuilding,
  } = useCustomCampaignShowContext();

  const isHovering = targetDropZoneEdgeInternalId === data.edgeInternalId;

  return (
    <div style={{
      ...dropZoneStyle,
      backgroundColor: isHovering ? "#E9EAEB" : "transparent",
      border: isHovering ? "1px dashed #D5D7DA" : "none",
    }} onClick={() => { setSelectedNode(null); setBuilding(true); }}>
      <Handle
        type="source"
        position={Position.Bottom}
        isConnectable={false}
        style={{visibility: "hidden"}}
      />
      {
        isHovering ? null : (
          <div style={nodeStyle}>
            <i className="fas fa-plus" />
          </div>
        )
      }
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={false}
        style={{visibility: "hidden"}}
      />
    </div>
  );
});
