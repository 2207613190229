import React, { useState, useEffect } from "react";
import { Panel } from "@xyflow/react";

import { useCustomCampaignShowContext } from "../CustomCampaignShowContainer";
import TriggerNodeForm from "./TriggerNodeForm";

const sidebarStyle = {
  minWidth: "200px",
  padding: "20px",
  width: "fit-content",
  height: "fit-content",
  backgroundColor: "white",
  marginRight: "10px",
  borderRadius: "10px",
  boxShadow: "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  color: "#333333",
};

const headerContainerStyle = {
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  justifyContent: "space-between",
  width: "100%",
  gap: 10,
  marginBottom: 20,
};

const saveButtonStyle = {
  width: "100%",
  padding: "8px 15px",
  borderRadius: 8,
  border: "1px solid #E9EAEB",
  backgroundColor: "#1982C4",
  marginTop: 20,
  fontWeight: 600,
};

export default () => {
  const {
    selectedNode,
    selectedNodeData,
    setSelectedNode,
    selectedNodeWorkflowErrors,
    updateSelectedNodeData,
  } = useCustomCampaignShowContext();

  const [saveDisabled, setSaveDisabled] = useState(false);

  const nodeType = selectedNode?.type;

  useEffect(() => {
    setSaveDisabled(
      Object.keys(selectedNodeWorkflowErrors).length > 0 ||
        _.isEqual(selectedNodeData, selectedNode?.data?.nodeData)
    );
  }, [selectedNodeWorkflowErrors]);

  return (
    <Panel position="top-right">
      <div style={sidebarStyle}>
        <div style={headerContainerStyle}>
          <div className="figma-icon-box">
            <i
              className={`${selectedNode?.data?.icon} figma-text secondary xxl`}
              style={{ padding: 10 }}
            />
          </div>
          <div className="d-flex flex-column">
            <div className="figma-text lg semibold">
              {selectedNode?.data?.title}
            </div>
            { nodeType === "WorkflowNodes::TriggerNode" ? (
              <div className="figma-text sm tertiary">Triggers specify when a fan enters a campaign</div>
            ) : null }
          </div>
          <button className="btn btn-link" onClick={() => setSelectedNode(null)}>
            <i className="far fa-xmark figma-text xl quinary" />
          </button>
        </div>
        {
          nodeType === "WorkflowNodes::TriggerNode" ? <TriggerNodeForm /> :
          <div className="figma-text sm tertiary">Form Body</div>
        }
        <button disabled={saveDisabled} style={{
          ...saveButtonStyle,
          backgroundColor: saveDisabled ? "#E9EAEB" : "#1982C4",
          color: saveDisabled ? "#A4A7AE" : "#FFFFFF",
        }} onClick={() => updateSelectedNodeData()}>
          Save
        </button>
      </div>
    </Panel>
  );
};
