import PropTypes from 'prop-types';
import React, { useRef, useEffect, useState } from 'react';

import TagFilter from './TagFilter';

import DayPicker from 'react-day-picker';
import Popup from "reactjs-popup";
import { SketchPicker } from 'react-color'
const axios = require('axios').default;

import $ from 'jquery';
require('datatables.net-bs4');

const contentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "201px",
  "padding": "15px 15px 10px 15px"
}

const CustomNavbar = ({ onPreviousClick, onNextClick, className }) => {
  return (
    <div className={`${className} d-flex justify-content-end`}>
      <button onClick={() => onPreviousClick()} className="custom-prev">
        <i className="fas fa-chevron-left"></i>
      </button>
      <button onClick={() => onNextClick()} className="custom-next">
        <i className="fas fa-chevron-right"></i>
      </button>
    </div>
  );
};

const autoSaveVenueOwnership = (csrfToken, team, venueOwnership, dataTableEl) => {
  axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;

  axios.patch(`/teams/${team.id}/venue_ownerships/${venueOwnership.id}`, {
    venue_ownership: {
      calendar_color_hex: venueOwnership.calendar_color_hex
    }
  }).then(() => {
    $(dataTableEl).DataTable().ajax.reload();
  });
};

const VenueCheckBox = ({
  venue,
  venueOwnerships,
  selectedVenues,
  selectedTags,
  setSelectedVenues,
  dataTableFilters,
  dataTableFiltersChanged,
  fetchCalendarEventsForSelectedVenues,
  fetchTeamVenueConflicts,
  saveTeamMembershipCalendarState,
  team,
  csrfToken,
  canSelectMultipleVenues,
  user,
  currentTeamMembership,
  venueOwnershipsChanged,
  dataTableEl
}) => {
  var venueOwnership = venueOwnerships
    .find(
      (vo) => {
        return (
          vo.venueable_type === "Venue" &&
            vo.venueable_id === venue.id
        );
      }
    );

  const [currentColor, setCurrentColor] = useState(venueOwnership.calendar_color_hex);

  var isChecked = selectedVenues
    .map((v) => v.value)
    .includes(venue.id);

  return (
    <div className='d-flex align-items-center justify-content-between my-calendar-venue-option'
          style={{"height": "22px"}}>
      <div className="form-check w-100 d-flex align-items-center">
        <input className="form-check-input"
                style={{
                  "marginTop": "0px",
                  "background": isChecked ? venueOwnership.calendar_color_hex : "white",
                  "border": isChecked ? `1px solid ${venueOwnership.calendar_color_hex}` : "1px solid #333",
                }}
                type="checkbox"
                checked={isChecked}
                onChange={
                  (e) => {
                    var updated = [];

                    if(canSelectMultipleVenues){
                      if(e.target.checked){
                        updated = [...selectedVenues, { value: venue.id, label: venue.name }];
                      } else {
                        updated = selectedVenues.filter((s) => s.value !== venue.id);
                      }
                    } else {
                      if(e.target.checked){
                        updated = [{ value: venue.id, label: venue.name }];
                      } else {
                        updated = [];
                      }
                    }

                    setSelectedVenues(updated);
                    fetchCalendarEventsForSelectedVenues(team, updated, selectedTags);
                    fetchTeamVenueConflicts(team, updated, user);

                    dataTableFiltersChanged(Object.assign({}, dataTableFilters, {
                      venue_ids: updated.map((s) =>  s.value)
                    }));

                    saveTeamMembershipCalendarState(csrfToken, team, currentTeamMembership, {
                      calendar_venue_ids: updated.map((s) => s.value)
                    });
                  }
                }
                id={`calendar-venue-${venue.id}`} />
        <label className="form-check-label text-truncate w-100"
                style={{
                  fontSize: "12px",
                  marginLeft: "-3px",
                  maxWidth: "150px",
                  marginBottom: "-2px"
                }}
                title={venue.name}
                htmlFor={`calendar-venue-${venue.id}`}>
          {venue.name}
        </label>
      </div>
      <Popup arrow={false}
            offsetY={3}
            offsetX={8}
            position="bottom right"
            contentStyle={contentStyle}
            onOpen={
              (e) => {
                e.preventDefault();
              }
            }
            trigger={open => (
        <a href="#"
            onClick={
              (e) => {
                e.preventDefault();
              }
            }
            className='text-dark small venue-color-control'>
          <i className="fas fa-sliders-h"></i>
        </a>
      )} >
        {close => (
          <div className='venue-color-popup'>
            <p className='mb-0'
                style={{"fontSize": "14px"}}>
              <strong>Choose calendar color</strong>
            </p>
            <SketchPicker width={"169px"}
                          color={currentColor}
                          onChange={
                            (color) => {
                              setCurrentColor(color.hex);
                            }
                          }
                          onChangeComplete={
                            (color) => {
                              var updatedVenueOwnership = {
                                ...venueOwnership,
                                calendar_color_hex: color.hex
                              };

                              var updatedVenueOwnerships = [...venueOwnerships].map((vo) => {
                                if(vo.id === venueOwnership.id){
                                  return updatedVenueOwnership;
                                } else {
                                  return vo;
                                }
                              });

                              venueOwnershipsChanged(updatedVenueOwnerships);
                              autoSaveVenueOwnership(csrfToken, team, updatedVenueOwnership, dataTableEl);
                            }
                          }
                          presetColors={[]} />
          </div>
        )}
      </Popup>
    </div>
  );
};

const CategoryCheckBox = ({
  htmlFor,
  label,
  isChecked,
  onChange
}) => {
  return (
    <div className='d-flex align-items-center justify-content-between my-calendar-venue-option py-0' style={{marginTop: "3px"}}>
      <div className="form-check w-100 d-flex align-items-center">
        <input className="form-check-input"
                style={{
                  "marginTop": "0px",
                  "background": isChecked ? "#1f81c2" : "white",
                  "border": isChecked ? `1px solid #1f81c2` : "1px solid #d9d9d9",
                }}
                type="checkbox"
                checked={isChecked}
                onChange={onChange}
                id={htmlFor} />
        <label className="form-check-label w-100"
                style={{
                  fontSize: "12px",
                  marginLeft: "-3px"
                }}
                htmlFor={htmlFor}>
          {label}
        </label>
      </div>
    </div>
  );
};

const CalendarMenu = ({
  team,
  csrfToken,
  user,
  currentTeamMembership,
  saveTeamMembershipCalendarState,
  currentTeamMembershipChanged,
  calendarStartDate,
  calendarRef,
  venuesOptions,
  selectedVenues,
  setSelectedVenues,
  fetchCalendarEventsForSelectedVenues,
  selectedTags,
  fetchTeamVenueConflicts,
  dataTableFilters,
  dataTableFiltersChanged,
  venueOwnerships,
  canSelectMultipleVenues,
  venueOwnershipsChanged,
  onSaleEnabled,
  announcedEnabled,
  confirmedEnabled,
  holdsEnabled,
  pastEventsEnabled,
  cancelledEventsEnabled,
  toggleOnSaleEnabled,
  toggleAnnouncedEnabled,
  toggleConfirmedEnabled,
  toggleHoldsEnabled,
  togglePastEventsEnabled,
  toggleCancelledEventsEnabled,
  setSelectedTags,
  searchTermChanged,
  dataTableEl,
  preSaleEnabled,
  togglePreSaleEnabled,
  browser
}) => {
  const buttonClasses = (teamMembership, value) => {
    if(teamMembership.calendar_view === value){
      return "btn-outline-dark btn-outline-dark-calendar-menu";
    } else {
      return "btn-link text-muted";
    }
  }

  const miniCalendarRef = useRef(null);
  const highlightCurrentWeek = () => {
    var dayPicker = miniCalendarRef.current.dayPicker;
    var calendarRows = dayPicker.getElementsByClassName("DayPicker-Week");

    Array.from(calendarRows).forEach((row) => {
      if (row.querySelector('.DayPicker-Day--today')) {
        row.classList.add('DayPicker-Week--today');
      }
    });
  };
  useEffect(() => {
    setTimeout(() => {
      highlightCurrentWeek();
    }, 1);
  }, [miniCalendarRef, calendarStartDate]);

  useEffect(() => {
    if(Object.keys(calendarRef).length === 0){
      return false;
    }

    if(currentTeamMembership.calendar_view === "calendar"){
      calendarRef.getApi().changeView('dayGridMonth');
    } else if(currentTeamMembership.calendar_view === "calendar_weekly"){
      calendarRef.getApi().changeView('timeGridWeek');
    } else {
      // NOOP
    }
  }, [currentTeamMembership.calendar_view]);

  const allVenuesChecked = venuesOptions.every((venue) => {
    return selectedVenues.some((v) => v.value === venue.id);
  });

  const allCategoriesChecked = (
    onSaleEnabled
      && announcedEnabled
      && confirmedEnabled
      && holdsEnabled
      && pastEventsEnabled
      && cancelledEventsEnabled
      && preSaleEnabled
  );

  return(
    <div className='card border-0 shadow-1'
          style={{
            "width": "265px",
            "minWidth": "265px",
            "borderRadius": "8px"
          }}>
      {!browser.is.small && !browser.is.extraSmall ? (
        <>
          <div className='card-body flex-grow-0' style={{padding: "20px 15px 10px 15px"}}>
            <div className='d-flex justify-content-between align-items-center'>
              <span style={{fontSize: "16px", fontWeight: "600"}}>
                Calendar
              </span>
            </div>
          </div>
          <div className='card-body flex-grow-0' style={{padding: "0px 15px 20px 15px"}}>
            <div className='d-flex justify-content-between align-items-center'
                  style={{
                    border: "1px solid #F7F7F7",
                    background: "#FAFAFA",
                    borderRadius: "4px",
                    padding: "2px"
                  }}>
              <a href="#"
                  className={`btn btn-sm small ${buttonClasses(currentTeamMembership, "calendar_weekly")}`}
                  onClick={
                    (e) => {
                      e.preventDefault();

                      var updated = {
                        ...currentTeamMembership,
                        calendar_view: "calendar_weekly"
                      }

                      searchTermChanged("");
                      currentTeamMembershipChanged(updated);
                      saveTeamMembershipCalendarState(csrfToken, team, currentTeamMembership, {
                        calendar_view: updated.calendar_view
                      });
                    }
                  }
                  style={{"width": "62px"}}>
                <strong>Week</strong>
              </a>
              <a href="#"
                  className={`btn btn-sm small ${buttonClasses(currentTeamMembership, "calendar")}`}
                  onClick={
                    (e) => {
                      e.preventDefault();

                      var updated = {
                        ...currentTeamMembership,
                        calendar_view: "calendar"
                      }

                      searchTermChanged("");
                      currentTeamMembershipChanged(updated);
                      saveTeamMembershipCalendarState(csrfToken, team, currentTeamMembership, {
                        calendar_view: updated.calendar_view
                      });
                    }
                  }
                  style={{"width": "62px"}}>
                <strong>Month</strong>
              </a>
              <a href="#"
                  className={`btn btn-sm small ${buttonClasses(currentTeamMembership, "list")}`}
                  onClick={
                    (e) => {
                      e.preventDefault();

                      var updated = {
                        ...currentTeamMembership,
                        calendar_view: "list"
                      }

                      currentTeamMembershipChanged(updated);
                      saveTeamMembershipCalendarState(csrfToken, team, currentTeamMembership, {
                        calendar_view: updated.calendar_view
                      });
                    }
                  }
                  style={{"width": "62px"}}>
                <strong>List</strong>
              </a>
            </div>
          </div>
        </>
      ) : null}
      <div className='card-body flex-grow-0'
            style={{
              "padding": "10px 15px 5px 15px",
              "borderTop": "1px solid #f5f5f5"
            }}>
        <DayPicker
          ref={miniCalendarRef}
          className='day-picker-calendar-minimap'
          month={calendarStartDate}
          showOutsideDays={true}
          firstDayOfWeek={user.calendar_start_day}
          navbarElement={<CustomNavbar />}
          onMonthChange={highlightCurrentWeek}
          onDayClick={
            (date, {selected}) => {
              if(currentTeamMembership.calendar_view === "list"){
                return false;
              }

              if(calendarRef && Object.keys(calendarRef).length > 0){
                calendarRef.getApi().gotoDate(date);
              }
            }
          } />
      </div>
      <div className='card-body flex-grow-0' style={{padding: "20px 15px", "borderTop": "1px solid #f5f5f5"}}>
        <div className='d-flex justify-content-between align-items-center position-relative'>
          <p className='small mb-0'>
            <strong style={{fontSize: "14px"}}>My Calendars</strong>
          </p>
          <a href="#"
              onClick={
                (e) => {
                  e.preventDefault();

                  var updated = {
                    ...currentTeamMembership,
                    my_calendars_menu_open: !currentTeamMembership.my_calendars_menu_open
                  }

                  currentTeamMembershipChanged(updated);
                  saveTeamMembershipCalendarState(csrfToken, team, currentTeamMembership, {
                    my_calendars_menu_open: updated.my_calendars_menu_open
                  });
                }
              }
              className='text-muted small stretched-link'>
            {currentTeamMembership.my_calendars_menu_open ? (
              <i className="fas fa-chevron-up"></i>
            ) : (
              <i className="fas fa-chevron-down"></i>
            )}
          </a>
        </div>
        {currentTeamMembership.my_calendars_menu_open ? (
          <div className='row'>
            <div className='col-12'
                  style={{
                    marginTop: "5px",
                    padding: "0 11px"
                  }}>
              {canSelectMultipleVenues ? (
                <div className='d-flex align-items-center justify-content-between my-calendar-venue-option'
                      style={{"height": "22px"}}>
                  <div className="form-check w-100 d-flex align-items-center">
                    <input className="form-check-input"
                            style={{
                              "marginTop": "0px",
                              "background": allVenuesChecked ? "#1982C4" : "white",
                              "border": allVenuesChecked ? `1px solid #1982C4` : "1px solid #333",
                            }}
                            type="checkbox"
                            checked={allVenuesChecked}
                            onChange={
                              (e) => {
                                var updated = [];

                                if(allVenuesChecked){
                                  updated = [];
                                } else {
                                  updated = [...venuesOptions].map((v) => {
                                    return { "value": v.id, "label": v.name }
                                  });
                                }

                                setSelectedVenues(updated);
                                fetchCalendarEventsForSelectedVenues(team, updated, selectedTags);
                                fetchTeamVenueConflicts(team, updated, user);

                                dataTableFiltersChanged(Object.assign({}, dataTableFilters, {
                                  venue_ids: updated.map((s) =>  s.value)
                                }));

                                saveTeamMembershipCalendarState(csrfToken, team, currentTeamMembership, {
                                  calendar_venue_ids: updated.map((s) => s.value)
                                });
                              }
                            }
                            id={`calendar-venue-select-all`} />
                    <label className="form-check-label text-truncate w-100"
                            style={{
                              fontSize: "12px",
                              marginLeft: "-3px",
                              maxWidth: "150px",
                              marginBottom: "-2px"
                            }}
                            htmlFor={`calendar-venue-select-all`}>
                      Select all
                    </label>
                  </div>
                </div>
              ) : null}
              {venuesOptions.map((venue, index) => 
                <VenueCheckBox key={index}
                                selectedVenues={selectedVenues}
                                venueOwnerships={venueOwnerships}
                                selectedTags={selectedTags}
                                setSelectedVenues={setSelectedVenues}
                                dataTableFilters={dataTableFilters}
                                dataTableFiltersChanged={dataTableFiltersChanged}
                                fetchCalendarEventsForSelectedVenues={fetchCalendarEventsForSelectedVenues}
                                fetchTeamVenueConflicts={fetchTeamVenueConflicts}
                                saveTeamMembershipCalendarState={saveTeamMembershipCalendarState}
                                team={team}
                                csrfToken={csrfToken}
                                user={user}
                                currentTeamMembership={currentTeamMembership}
                                canSelectMultipleVenues={canSelectMultipleVenues}
                                venueOwnershipsChanged={venueOwnershipsChanged}
                                dataTableEl={dataTableEl}
                                venue={venue} />
              )}
            </div>
          </div>
        ) : null}
      </div>
      <div className='card-body flex-grow-0' style={{padding: "20px 15px", "borderTop": "1px solid #f5f5f5"}}>
        <div className='d-flex justify-content-between align-items-center position-relative'>
          <p className='small mb-0'>
            <strong style={{fontSize: "14px"}}>Categories</strong>
          </p>
          <a href="#"
              onClick={
                (e) => {
                  e.preventDefault();

                  var updated = {
                    ...currentTeamMembership,
                    categories_menu_open: !currentTeamMembership.categories_menu_open
                  }

                  currentTeamMembershipChanged(updated);
                  saveTeamMembershipCalendarState(csrfToken, team, currentTeamMembership, {
                    categories_menu_open: updated.categories_menu_open
                  });
                }
              }
              className='text-muted small stretched-link'>
            {currentTeamMembership.categories_menu_open ? (
              <i className="fas fa-chevron-up"></i>
            ) : (
              <i className="fas fa-chevron-down"></i>
            )}
          </a>
        </div>
        {currentTeamMembership.categories_menu_open ? (
          <div className='row'>
            <div className='col-12'
                  style={{
                    marginTop: "5px",
                    padding: "0 11px"
                  }}>
              <CategoryCheckBox htmlFor={"calendar-categories-select-all"}
                          isChecked={allCategoriesChecked}
                          label={
                            <div className='d-flex align-items-center'>
                              <div className='text-truncate'>
                                Select all
                              </div>
                            </div>
                          }
                          onChange={
                            (e) => {
                              if(allCategoriesChecked){
                                toggleOnSaleEnabled(false);
                                toggleAnnouncedEnabled(false);
                                toggleConfirmedEnabled(false);
                                toggleHoldsEnabled(false);
                                togglePastEventsEnabled(false);
                                toggleCancelledEventsEnabled(false);
                                togglePreSaleEnabled(false);

                                saveTeamMembershipCalendarState(csrfToken, team, currentTeamMembership, {
                                  calendar_on_sale_enabled: false,
                                  calendar_announced_enabled: false,
                                  calendar_holds_enabled: false,
                                  calendar_confirmed_enabled: false,
                                  calendar_past_events_enabled: false,
                                  calendar_cancelled_events_enabled: false,
                                  calendar_pre_sale_enabled: false
                                });
                              } else {
                                toggleOnSaleEnabled(true);
                                toggleAnnouncedEnabled(true);
                                toggleConfirmedEnabled(true);
                                toggleHoldsEnabled(true);
                                togglePastEventsEnabled(true);
                                toggleCancelledEventsEnabled(true);
                                togglePreSaleEnabled(true);

                                saveTeamMembershipCalendarState(csrfToken, team, currentTeamMembership, {
                                  calendar_on_sale_enabled: true,
                                  calendar_announced_enabled: true,
                                  calendar_holds_enabled: true,
                                  calendar_confirmed_enabled: true,
                                  calendar_past_events_enabled: true,
                                  calendar_cancelled_events_enabled: true,
                                  calendar_pre_sale_enabled: true
                                });
                              }
                            }
                          } />
              <CategoryCheckBox htmlFor={"calendar-holds-enabled"}
                                isChecked={holdsEnabled}
                                label={
                                  <div className='d-flex align-items-center'>
                                    <img src="icons/hold.svg" style={{width: "14px", marginRight: "4px"}} />
                                    <div className='text-truncate'>
                                      Holds
                                    </div>
                                  </div>
                                }
                                onChange={
                                  (e) => {
                                    toggleHoldsEnabled(!holdsEnabled);

                                    saveTeamMembershipCalendarState(csrfToken, team, currentTeamMembership, {
                                      calendar_holds_enabled: !holdsEnabled
                                    });
                                  }
                                } />
              <CategoryCheckBox htmlFor={"calendar-confirmed-enabled"}
                                isChecked={confirmedEnabled}
                                label={
                                  <div className='d-flex align-items-center'>
                                    <img src="icons/calendar-check.svg" style={{width: "14px", marginRight: "4px"}} />
                                    <div className='text-truncate'>
                                      Confirms
                                    </div>
                                  </div>
                                }
                                onChange={
                                  (e) => {
                                    toggleConfirmedEnabled(!confirmedEnabled);

                                    saveTeamMembershipCalendarState(csrfToken, team, currentTeamMembership, {
                                      calendar_confirmed_enabled: !confirmedEnabled
                                    });
                                  }
                                } />
              <CategoryCheckBox htmlFor={"calendar-announced-enabled"}
                                isChecked={announcedEnabled}
                                label={
                                  <div className='d-flex align-items-center'>
                                    <img src="icons/announcement.svg" style={{width: "14px", marginRight: "4px"}} />
                                    <div className='text-truncate'>
                                      Announce
                                    </div>
                                  </div>
                                }
                                onChange={
                                  (e) => {
                                    toggleAnnouncedEnabled(!announcedEnabled);

                                    saveTeamMembershipCalendarState(csrfToken, team, currentTeamMembership, {
                                      calendar_announced_enabled: !announcedEnabled
                                    });
                                  }
                                } />
              <CategoryCheckBox htmlFor={"calendar-pre-sale-events-enabled"}
                                isChecked={preSaleEnabled}
                                label={
                                  <div className='d-flex align-items-center'>
                                    <img src="icons/pre-sale.svg" style={{width: "14px", marginRight: "4px"}} />
                                    <div className='text-truncate'>
                                      Pre-sale
                                    </div>
                                  </div>
                                }
                                onChange={
                                  (e) => {
                                    togglePreSaleEnabled(!preSaleEnabled);

                                    saveTeamMembershipCalendarState(csrfToken, team, currentTeamMembership, {
                                      calendar_pre_sale_enabled: !preSaleEnabled
                                    });
                                  }
                                } />
              <CategoryCheckBox htmlFor={"calendar-on-sale-enabled"}
                                isChecked={onSaleEnabled}
                                label={
                                  <div className='d-flex align-items-center'>
                                    <img src="icons/ticket.svg" style={{width: "14px", marginRight: "4px"}} />
                                    <div className='text-truncate'>
                                      On Sale
                                    </div>
                                  </div>
                                }
                                onChange={
                                  (e) => {
                                    toggleOnSaleEnabled(!onSaleEnabled);

                                    saveTeamMembershipCalendarState(csrfToken, team, currentTeamMembership, {
                                      calendar_on_sale_enabled: !onSaleEnabled
                                    });
                                  }
                                } />
              <CategoryCheckBox htmlFor={"calendar-past-events-enabled"}
                                isChecked={pastEventsEnabled}
                                label={
                                  <div className='d-flex align-items-center'>
                                    <img src="icons/past.svg" style={{width: "14px", marginRight: "4px"}} />
                                    <div className='text-truncate'>
                                      Past Events
                                    </div>
                                  </div>
                                }
                                onChange={
                                  (e) => {
                                    togglePastEventsEnabled(!pastEventsEnabled);

                                    saveTeamMembershipCalendarState(csrfToken, team, currentTeamMembership, {
                                      calendar_past_events_enabled: !pastEventsEnabled
                                    });
                                  }
                                } />
              <CategoryCheckBox htmlFor={"calendar-cancelled-events-enabled"}
                                isChecked={cancelledEventsEnabled}
                                label={
                                  <div className='d-flex align-items-center'>
                                    <img src="icons/x-square.svg" style={{width: "14px", marginRight: "4px"}} />
                                    <div className='text-truncate'>
                                      Canceled Events
                                    </div>
                                  </div>
                                }
                                onChange={
                                  (e) => {
                                    toggleCancelledEventsEnabled(!cancelledEventsEnabled);

                                    saveTeamMembershipCalendarState(csrfToken, team, currentTeamMembership, {
                                      calendar_cancelled_events_enabled: !cancelledEventsEnabled
                                    });
                                  }
                                } />
            </div>
          </div>
        ) : null}
      </div>

      <div className='card-body flex-grow-0' style={{padding: "20px 15px", "borderTop": "1px solid #f5f5f5"}}>
        <div className='d-flex justify-content-between align-items-center position-relative'>
          <p className='small mb-0'>
            <strong style={{fontSize: "14px"}}>Tags</strong>
          </p>
          <a href="#"
              onClick={
                (e) => {
                  e.preventDefault();

                  var updated = {
                    ...currentTeamMembership,
                    tags_menu_open: !currentTeamMembership.tags_menu_open
                  }

                  currentTeamMembershipChanged(updated);
                  saveTeamMembershipCalendarState(csrfToken, team, currentTeamMembership, {
                    tags_menu_open: updated.tags_menu_open
                  });
                }
              }
              className='text-muted small stretched-link'>
            {currentTeamMembership.tags_menu_open ? (
              <i className="fas fa-chevron-up"></i>
            ) : (
              <i className="fas fa-chevron-down"></i>
            )}
          </a>
        </div>
        {currentTeamMembership.tags_menu_open ? (
          <div className='row'>
            <div className='col-12'
                  style={{
                    marginTop: "5px",
                    padding: "0 11px",
                    maxHeight: "200px",
                    overflowY: "auto"
                  }}>
              <TagFilter team={team}
                          csrfToken={csrfToken}
                          scope="CalendarEvent"
                          currentTags={selectedTags}
                          onChange={tags => {
                            setSelectedTags(current => {
                              fetchCalendarEventsForSelectedVenues(team, selectedVenues, tags);
                              dataTableFiltersChanged({
                                ...dataTableFilters,
                                tags: tags
                              });
                              return tags;
                            });
                          }}
                        />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

CalendarMenu.propTypes = {
  team: PropTypes.object.isRequired,
  csrfToken: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  currentTeamMembership: PropTypes.object.isRequired,
  saveTeamMembershipCalendarState: PropTypes.func.isRequired,
  currentTeamMembershipChanged: PropTypes.func.isRequired,
  calendarStartDate: PropTypes.instanceOf(Date),
  calendarRef: PropTypes.object,
  venuesOptions: PropTypes.array,
  selectedVenues: PropTypes.array,
  setSelectedVenues: PropTypes.func.isRequired,
  fetchCalendarEventsForSelectedVenues: PropTypes.func.isRequired,
  selectedTags: PropTypes.array,
  fetchTeamVenueConflicts: PropTypes.func.isRequired,
  dataTableFilters: PropTypes.object,
  dataTableFiltersChanged: PropTypes.func.isRequired,
  venueOwnerships: PropTypes.array,
  canSelectMultipleVenues: PropTypes.bool,
  venueOwnershipsChanged: PropTypes.func.isRequired,
  onSaleEnabled: PropTypes.bool,
  announcedEnabled: PropTypes.bool,
  confirmedEnabled: PropTypes.bool,
  holdsEnabled: PropTypes.bool,
  pastEventsEnabled: PropTypes.bool,
  cancelledEventsEnabled: PropTypes.bool,
  toggleOnSaleEnabled: PropTypes.func.isRequired,
  toggleAnnouncedEnabled: PropTypes.func.isRequired,
  toggleConfirmedEnabled: PropTypes.func.isRequired,
  toggleHoldsEnabled: PropTypes.func.isRequired,
  togglePastEventsEnabled: PropTypes.func.isRequired,
  toggleCancelledEventsEnabled: PropTypes.func.isRequired,
  setSelectedTags: PropTypes.func.isRequired,
  searchTermChanged: PropTypes.func.isRequired,
  dataTableEl: PropTypes.object,
  preSaleEnabled: PropTypes.bool,
  togglePreSaleEnabled: PropTypes.func.isRequired,
  browser: PropTypes.object.isRequired
};

export default CalendarMenu;
