import React from "react";

import { useCustomCampaignShowContext } from "../CustomCampaignShowContainer";

const rowContainerStyle = {
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  gap: 10,
  marginBottom: 10,
  padding: 10,
  border: "1px solid #E9EAEB",
  borderRadius: 8,
  width: "100%",
  cursor: "pointer",
};

const backLinkStyle = {
  color: "#136395",
  fontWeight: 600,
  cursor: "pointer",
  marginBottom: 10,
  fontSize: 12,
};

export default () => {
  const {
    selectedNodeData,
    setSelectedNodeData,
  } = useCustomCampaignShowContext();

  const setTriggerType = (type) => {
    setSelectedNodeData({
      ...selectedNodeData,
      trigger_type: type,
    });
  };

  return (
    <>
      {!!selectedNodeData?.trigger_type ? (
        <>
          <a href="#" onClick={() => setTriggerType(null)} style={backLinkStyle}>
            {`<   Change Trigger`}
          </a>
          {selectedNodeData.trigger_type == "event" ? (
            <div className="flex flex-col gap-2">
              <div className="figma-text sm semibold secondary">Event</div>
            </div>
          ) : null}
        </>
      ) : (
        <>
          <div style={rowContainerStyle} onClick={() => setTriggerType("event")}>
            <div className="figma-icon-box">
              <svg className="sprite-icon figma-icon secondary" style={{ padding: 4}}>
                <use xlinkHref="#pointer-click" />
              </svg>
            </div>
            <div className="d-flex flex-column">
              <div className="figma-text sm semibold">Event</div>
              <div className="figma-text sm tertiary">A fan performs an event.</div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
