/* eslint-disable import/prefer-default-export */

export const VENUE_SELECTED_CHANGED = 'VENUE_SELECTED_CHANGED';
export const VENUE_OWNERSHIPS_CHANGED = 'VENUE_OWNERSHIPS_CHANGED';
export const EVENTS_CHANGED = 'EVENTS_CHANGED';
export const DATA_TABLE_EL_CHANGED = 'DATA_TABLE_EL_CHANGED';

export const CALENDAR_EVENT_CONFLICTS_CHANGED = 'CALENDAR_EVENT_CONFLICTS_CHANGED';
export const OPEN_CALENDAR_EVENT_CONFLICTS_MODAL = 'OPEN_CALENDAR_EVENT_CONFLICTS_MODAL';
export const CLOSE_CALENDAR_EVENT_CONFLICTS_MODAL = 'CLOSE_CALENDAR_EVENT_CONFLICTS_MODAL';
export const CALENDAR_EVENT_CONFLICTS_MERGE_CHECKBOX_CLICKED = 'CALENDAR_EVENT_CONFLICTS_MERGE_CHECKBOX_CLICKED';

export const USER_UPDATED = 'USER_UPDATED';
export const CALENDAR_START_DATE_UPDATED = 'CALENDAR_START_DATE_UPDATED';

export const TOGGLE_ON_SALE_ENABLED = 'TOGGLE_ON_SALE_ENABLED';
export const TOGGLE_PRE_SALE_ENABLED = 'TOGGLE_PRE_SALE_ENABLED';
export const TOGGLE_ANNOUNCED_ENABLED = 'TOGGLE_ANNOUNCED_ENABLED';
export const TOGGLE_CONFIRMED_ENABLED = 'TOGGLE_CONFIRMED_ENABLED';
export const TOGGLE_HOLDS_ENABLED = 'TOGGLE_HOLDS_ENABLED';
export const TOGGLE_PAST_EVENTS_ENABLED = 'TOGGLE_PAST_EVENTS_ENABLED';
export const TOGGLE_CANCELLED_EVENTS_ENABLED = 'TOGGLE_CANCELLED_EVENTS_ENABLED';

export const DATA_TABLE_FILTERS_CHANGED = 'DATA_TABLE_FILTERS_CHANGED';
export const RELOAD_DATA_TABLE = 'RELOAD_DATA_TABLE';

export const SEARCH_TERM_CHANGED = 'SEARCH_TERM_CHANGED';
export const CURRENT_TEAM_MEMBERSHIP_CHANGED = 'CURRENT_TEAM_MEMBERSHIP_CHANGED';
export const CHECK_AVAILS_IS_OPEN_CHANGED = 'CHECK_AVAILS_IS_OPEN_CHANGED';
export const CHECK_AVAILS_SETTINGS_CHANGED = 'CHECK_AVAILS_SETTINGS_CHANGED';
export const CHECK_AVAILS_DATES_CHANGED = 'CHECK_AVAILS_DATES_CHANGED';
export const CHECK_AVAILS_CALENDAR_EVENTS_CHANGED = 'CHECK_AVAILS_CALENDAR_EVENTS_CHANGED';
export const CHECK_AVAILS_LOADING_CHANGED = 'CHECK_AVAILS_LOADING_CHANGED';
export const CALENDAR_SYNC_IS_OPEN_CHANGED = 'CALENDAR_SYNC_IS_OPEN_CHANGED';
