import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const ChooseAddOns = ({
  team,
  csrfToken,
  confirm,
  ticketTypes,
  addOns,
  buildTicketReservation,
  visibleAddOns,
  promoCode,
  addOnsChanged,
  addOnQuantityOptions,
  ticketReservation,
  userContext,
  postToParent,
  formatItemsForGoogleTagManager,
  addOnIsSeated
}) => (
  <div>
    {visibleAddOns.filter((addOn) => !addOnIsSeated(addOn)).sort((a, b) => a.position - b.position).map((addOn, index) =>
      <div className="card border-0"
           style={{"background": "#f9f9f9", "marginBottom": "15px"}}
           key={index}>
        <div className="card-body"
             style={{
               "paddingLeft": "15px",
               "paddingRight": "15px"
             }}>
          <div className="row">
            <div className="col">
              <p className="mb-0"
                 style={{"fontSize": "16px"}}>
                <strong>{addOn.name}</strong>
              </p>
              <p className="mb-0" style={{"fontSize": "16px"}}>
                <span>
                  {formatter.format(parseFloat(addOn.calculated_price))}
                </span>
              </p>
              {confirm.enable_all_in_pricing ? (
                <p className="mt-2 mb-0 small text-muted">
                  <i className="fas fa-info-circle mr-1"></i>
                  Price includes all fees, including taxes if applicable
                </p>
              ) : null}
              {addOn.description && addOn.description.length > 0 ? (
                <div className="mb-0 mt-2 small last-p-margin-0"
                     dangerouslySetInnerHTML={{__html: addOn.simple_format_description}}
                     style={{"maxWidth": "400px"}}>
                </div>
              ) : null}
            </div>
            {(addOn.available_tickets == 0 || addOn.sold_out) ? (
              <div className="col d-flex align-items-center justify-content-end">
                <p className="mb-0"
                   style={{"fontSize": "14px", "color": "#686868"}}>
                  Sold out
                </p>
              </div>
            ) : (
              <div className="col-xs-auto d-flex justify-content-end"
                   style={{"paddingRight": "15px"}}>
                <div className="form-group mb-0">
                  <select className="form-control form-control-lg"
                          aria-label="Add-on Quantity"
                          onChange={
                            (e) => {
                              var quantity = parseInt(e.target.value);

                              var updated = [...addOns].map((ao) => {
                                if(ao.id === addOn.id){
                                  return Object.assign({}, ao, {quantity: quantity});
                                } else {
                                  return ao;
                                }
                              });

                              if(quantity > 0){
                                var updatedItem = Object.assign({}, addOn, {
                                  quantity: quantity
                                });

                                var items = formatItemsForGoogleTagManager(
                                  confirm,
                                  promoCode,
                                  [],
                                  [updatedItem]
                                );

                                postToParent("ga.add_to_cart", items)
                              }

                              addOnsChanged(updated);
                              buildTicketReservation(csrfToken, confirm, ticketTypes, promoCode, updated, ticketReservation, userContext);
                            }
                          }
                          value={addOn.quantity || 0}
                          style={{"fontSize": "16px", "border": "1px solid #f3f3f3"}}>
                    {addOnQuantityOptions(addOn, ticketTypes, userContext).map((quantity, index) =>
                      <option key={index} value={quantity}>
                        {quantity}
                      </option>
                    )}
                  </select>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )}
  </div>
);

ChooseAddOns.propTypes = {
  team: PropTypes.object.isRequired,
  csrfToken: PropTypes.string.isRequired,
  confirm: PropTypes.object.isRequired,
  ticketTypes: PropTypes.array.isRequired,
  addOns: PropTypes.array.isRequired,
  buildTicketReservation: PropTypes.func.isRequired,
  visibleAddOns: PropTypes.array,
  promoCode: PropTypes.object,
  addOnsChanged: PropTypes.func.isRequired,
  addOnQuantityOptions: PropTypes.func.isRequired,
  ticketReservation: PropTypes.object,
  userContext: PropTypes.object,
  postToParent: PropTypes.func.isRequired,
  formatItemsForGoogleTagManager: PropTypes.func.isRequired,
  addOnIsSeated: PropTypes.func.isRequired
};

export default ChooseAddOns;
