import React from "react";
import { Panel, useReactFlow } from "@xyflow/react";
import axios from "axios";

import { useCustomCampaignShowContext } from "./CustomCampaignShowContainer";

const panelStyle = {
  display: "flex",
  flexDirection: "column",
};

const buttonContainerStyle = {
  display: "flex",
  flexDirection: "row",
  gap: 10,
  backgroundColor: "white",
  borderRadius: 5,
  padding: 5,
  justifyContent: "center",
  alignItems: "center",
  boxShadow: "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",

};

const borderedButtonStyle = {
  backgroundColor: "white",
  borderRadius: 5,
  padding: "5px 10px",
  display: "flex",
  flexDirection: "row",
  gap: 5,
  alignItems: "center",
  color: "#333333",
};

const spacerStyle = {
  width: 1,
  height: 24,
  backgroundColor: "#D5D7DA",
};

const standardButtonStyle = {
  border: "none",
  backgroundColor: "transparent",
  color: "#333333",
};

export default () => {
  const {
    building,
    setBuilding,
    selectedNode,
    setSelectedNode,
    setNodes,
    setEdges,
    csrfToken,
    team,
    customCampaign,
    flowNodes,
  } = useCustomCampaignShowContext();

  const { zoomIn, zoomOut, fitView } = useReactFlow();

  const handleBuildClicked = () => {
    if (selectedNode) {
      setBuilding(true);
      setSelectedNode(null);
    } else {
      setBuilding(!building);
    }
  };

  const handleDeleteClicked = () => {
    if (selectedNode) {
      axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
      axios.delete(`/teams/${team.id}/custom_campaigns/${customCampaign.id}/workflow_nodes/${selectedNode.data.internalId}`)
        .then(response => {
          setSelectedNode(null);
          setNodes(response.data.workflow_nodes);
          setEdges(response.data.workflow_edges);
        })
        .catch(error => {
          console.error("error", error);
        });
    }
  };

  const shouldShowDeleteButton = () => {
    return selectedNode
      && selectedNode.type !== "WorkflowNodes::TriggerNode"
      && selectedNode.type !== "WorkflowNodes::ExitNode";
  };

  return (
    <Panel position="bottom-center" style={panelStyle}>
      <div style={buttonContainerStyle}>
        <button onClick={handleBuildClicked} style={{
          ...borderedButtonStyle,
          border: building ? "1px solid #B2DBF5" : "1px solid #D5D7DA",
          color: building ? "#136395" : "black",
        }}>
          <i className="fa-solid fa-plus"/>
          Build
        </button>
        {shouldShowDeleteButton() ? (
          <>
            <div style={spacerStyle} />
            <button onClick={handleDeleteClicked} style={{
              ...borderedButtonStyle,
              color: "#B42318",
              border: "1px solid #FDA29B",
            }}>
              <i className="fa-solid fa-trash"/>
              Delete
            </button>
            <div style={spacerStyle} />
          </>
        ) : null}
        <button style={standardButtonStyle}
        onClick={() => zoomOut()}>
          <i className="far fa-magnifying-glass-minus"/>
        </button>
        <button style={standardButtonStyle} onClick={() => zoomIn()}>
          <i className="far fa-magnifying-glass-plus"/>
        </button>
        <button style={standardButtonStyle} onClick={() => fitView({
          nodes: flowNodes,
          duration: 750,
        })}>
          <i className="far fa-sitemap"/>
        </button>
      </div>
    </Panel>
  );
};
