import PropTypes from 'prop-types';
import React from 'react';

import Popup from "reactjs-popup";
import CSVImporter from '../../CSVImporter/CSVImporter';
const axios = require('axios').default;

import $ from 'jquery';
require('datatables.net-bs4');

const settingsContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "250px",
  "padding": "0px",
  "marginTop": "10px",
  "zIndex": "3000"
}

const CalendarOptions = ({
  csrfToken,
  user,
  team,
  saveUserCalendarStartDay,
  currentTeamMembership,
  checkAvailsIsOpenChanged,
  calendarSyncIsOpenChanged,
  fetchCalendarEventsForSelectedVenues,
  selectedVenues,
  children,
  dataTableEl,
  dataTableFilters,
  showExportCsv
}) => {
  const updateTeamMembership = (csrfToken, team, teamMembership, params, onSuccess) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
    axios.patch("/teams/" + team.id + "/team_memberships/" + teamMembership.id, {
        team_membership: params
      })
      .then(({ data }) => {
        onSuccess(data);
      })
  };

  return (
    <Popup arrow={false}
            offsetY={0}
            position="bottom right"
            contentStyle={settingsContentStyle}
            overlayStyle={{ zIndex: "2999" }}
            onOpen={
              (e) => {
                e.preventDefault();
              }
            }
            trigger={children} >
      {close => (
        <div className="row m-0">
          <div className="col-12 px-0">
            <div className='text-left pt-3 px-3'>
              <a href="#"
                  className="text-dark d-flex"
                  onClick={
                    (e) => {
                      e.preventDefault();
                      checkAvailsIsOpenChanged(true);
                      close();
                    }
                  }>
                <div className='d-flex justify-content-center align-items-center' style={{width: "16px", "marginRight": "10px"}}>
                  <i className="fas fa-calendar-check text-muted"></i>
                </div>
                <div className="flex-grow-1" style={{fontWeight: "600", "color": "#414651"}}>
                  Check avails
                </div>
              </a>
            </div>
          </div>
          {showExportCsv ? (
            <div className="col-12 px-0">
              <div className='text-left pt-2 px-3'>
                <a href="#"
                  className="text-dark d-flex"
                  onClick={
                    (e) => {
                      e.preventDefault();
                      close();

                      var dataTable = $(dataTableEl).DataTable();
                      var url = dataTable.ajax.url().replace(/\.json$/, ".csv");
                      var params = Object.assign({},
                        dataTable.ajax.params(),
                        dataTableFilters
                      );

                      var urlWithParams = (url + '?' + $.param(params));
                      window.location.href = urlWithParams;
                    }
                  }>
                  <div className='d-flex justify-content-center align-items-center' style={{width: "16px", "marginRight": "10px"}}>
                    <i className="fas fa-download text-muted"></i>
                  </div>
                  <div className="flex-grow-1" style={{fontWeight: "600", "color": "#414651"}}>
                    Export CSV
                  </div>
                </a>
              </div>
            </div>
          ) : null}
          {user.can_manage_csv_imports ? (
            <div className="col-12 px-0">
              <div className='text-left pt-2 px-3' style={{"marginTop": "5px"}}>
                <CSVImporter
                  csrfToken={csrfToken}
                  team={team}
                  importerKey={"3f14aa14-9ffb-4dc8-9138-02a6f005e472"}
                  type={"CSVImportRows::CalendarEvent"}
                  renderAnchor={(openModal) => (
                    <a href="#"
                      className="text-dark d-flex"
                      onClick={e => {
                        e.preventDefault();
                        openModal();
                      }}
                    >
                      <div className='d-flex justify-content-center align-items-center' style={{width: "16px", "marginRight": "10px"}}>
                        <i className="fas fa-arrow-from-top fa-flip-vertical text-muted"></i>
                      </div>
                      <div className="flex-grow-1" style={{fontWeight: "600", "color": "#414651"}}>
                        Import Events
                      </div>
                    </a>
                  )}
                  onClose={() => fetchCalendarEventsForSelectedVenues(team, selectedVenues, selectedTags)}
                />
              </div>
            </div>
          ) : null}
          {selectedVenues.length === 1 ? (
            <div className="col-12 px-0">
              <div className='text-left pt-2 px-3' style={{"marginTop": "5px"}}>
                <a href="#"
                  className="text-dark d-flex"
                  onClick={
                    (e) => {
                      e.preventDefault();
                      calendarSyncIsOpenChanged(true);
                      close();
                    }
                  }>
                  <div className='d-flex justify-content-center align-items-center' style={{width: "16px", "marginRight": "10px"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path d="M15.0007 8.15546C15.0007 7.57991 14.953 7.15991 14.8499 6.72437H8.14355V9.32211H12.08C12.0007 9.96769 11.5721 10.9399 10.6197 11.5932L10.6064 11.6802L12.7268 13.29L12.8737 13.3044C14.2229 12.0832 15.0007 10.2866 15.0007 8.15546" fill="#4285F4"/>
                      <path d="M8.14315 14.9999C10.0717 14.9999 11.6907 14.3776 12.8733 13.3043L10.6193 11.5931C10.0162 12.0054 9.20662 12.2932 8.14315 12.2932C6.25428 12.2932 4.65113 11.0721 4.07965 9.38428L3.99588 9.39125L1.79104 11.0635L1.76221 11.142C2.93679 13.4287 5.34949 14.9999 8.14315 14.9999Z" fill="#34A853"/>
                      <path d="M4.07982 9.38448C3.92903 8.94893 3.84176 8.48223 3.84176 8.00003C3.84176 7.51778 3.92903 7.05113 4.07189 6.61558L4.06789 6.52282L1.83542 4.82373L1.76237 4.85778C1.27827 5.80668 1.00049 6.87226 1.00049 8.00003C1.00049 9.1278 1.27827 10.1933 1.76237 11.1422L4.07982 9.38448" fill="#FBBC05"/>
                      <path d="M8.14319 3.70665C9.48444 3.70665 10.3892 4.27442 10.9051 4.7489L12.9209 2.82C11.6829 1.69222 10.0717 1 8.14319 1C5.34951 1 2.9368 2.5711 1.76221 4.85775L4.07173 6.61555C4.65114 4.92777 6.25431 3.70665 8.14319 3.70665" fill="#EB4335"/>
                    </svg>
                  </div>
                  <div className="flex-grow-1" style={{fontWeight: "600", "color": "#414651"}}>
                    Sync to Google Calendar
                  </div>
                </a>
              </div>
            </div>
          ) : null}
          <div className="col-12 px-0"
                style={{
                  "borderTop": "1px solid #E9EAEB",
                  "marginTop": "15px"
                }}>
            <div className='text-left px-3'
                  style={{"paddingTop": "12px", "paddingBottom": "12px"}}>
              <strong className="d-inline-block"
                      style={{"fontSize": "14px", "fontWeight": "600", "paddingBottom": "15px"}}>
                Week starts on
              </strong>
              <div className="form-check" style={{"marginBottom": "14px"}}>
                <label className="form-check-label" style={{"fontSize": "14px", "fontWeight": "600"}}>
                  <input type="radio"
                        className="form-check-input"
                        defaultChecked={user.calendar_start_day === 0}
                        onChange={
                          (e) => {
                            saveUserCalendarStartDay(csrfToken, 0);
                          }
                        }
                        name="day-of-week-choice" />
                  Sunday
                </label>
              </div>
              <div className="form-check">
                <label className="form-check-label" style={{"fontSize": "14px", "fontWeight": "600"}}>
                  <input type="radio"
                        className="form-check-input"
                        defaultChecked={user.calendar_start_day === 1}
                        onChange={
                          (e) => {
                            saveUserCalendarStartDay(csrfToken, 1);
                          }
                        }
                        name="day-of-week-choice" />
                  Monday
                </label>
              </div>
            </div>
          </div>
          {user.admin ? (
            <div className="col-12 px-0"
                  style={{
                    "borderTop": "1px solid #E9EAEB"
                  }}>
              <div style={{"padding": "8px 10px"}}>
                <a className='btn btn-white btn-block clickable hover-only'
                    style={{
                      "borderWidth": "0px",
                      "padding": "2px 6px"
                    }}
                    onClick={
                      (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        
                        var updated = {
                          ...currentTeamMembership,
                          calendar_version: (currentTeamMembership.calendar_version === "v2" ? "v1" : "v2")
                        }

                        updateTeamMembership(csrfToken, team, currentTeamMembership, {
                          calendar_version: updated.calendar_version
                        }, () => {
                          location.reload();
                        });
                      }
                    }
                    href="#">
                  <div className="custom-control custom-switch d-flex calendar-version-toggle">
                    <input type="checkbox"
                            className="custom-control-input"
                            checked={currentTeamMembership.calendar_version === "v2"}
                            onChange={
                              (e) => {
                                e.preventDefault();
                              }
                            }
                            id="team-membership-calendar-version-toggle"/>
                    <label className="custom-control-label clickable"
                              style={{
                                "fontSize": "14px",
                                "fontWeight": "600"
                              }}
                              htmlFor="team-membership-calendar-version-toggle">
                      Try the new calendar
                    </label>
                  </div>
                </a>
              </div>
            </div>
          ) : null}
        </div>
      )}
    </Popup>
  )
};

CalendarOptions.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  team: PropTypes.object.isRequired,
  saveUserCalendarStartDay: PropTypes.func.isRequired,
  currentTeamMembership: PropTypes.object.isRequired,
  checkAvailsIsOpenChanged: PropTypes.func.isRequired,
  calendarSyncIsOpenChanged: PropTypes.func.isRequired,
  fetchCalendarEventsForSelectedVenues: PropTypes.func.isRequired,
  selectedVenues: PropTypes.array,
  children: PropTypes.element,
  dataTableEl: PropTypes.object,
  dataTableFilters: PropTypes.object,
  showExportCsv: PropTypes.bool
};

export default CalendarOptions;
