import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { SeatsioSeatingChart } from '@seatsio/seatsio-react';

import { ticketTypeAvailable, salesStartInFuture } from './WebOrderForm';
import CalendarEventInterestForm from './CalendarEventInterestForm';

var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

var _ = require('lodash');
var debouncedBuildTicketReservation;
var seatsIOObjectSelected;
var seatsIOObjectDeselected;

const ChooseSeatedTicketTypes = ({
  team,
  csrfToken,
  confirm,
  ticketTypes,
  promoCode,
  ticketTypesChanged,
  buildTicketReservation,
  visibleTicketTypes,
  addOns,
  ticketReservation,
  postToParent,
  formatItemsForGoogleTagManager,
  seatsIOPublicKey,
  seatsIOChart,
  seatsIOChartChanged,
  seatsIOSelectedObjects,
  seatsIOSelectedObjectsChanged,
  selectedObjectLabel,
  userContext,
  selectedObjectType,
  onSelectionChange,
  announceListEnabled,
  waitlistEnabled,
  no_orphan_seats
}) => {
  const [availableTickets, setAvailableTickets] = useState([]);
  const [visibleTickets, setVisibleTickets] = useState([]);
  const [scheduledTickets, setScheduledTickets] = useState([]);

  useEffect(() => {
    debouncedBuildTicketReservation = _.debounce((csrfToken, confirm, updated, promoCode, addOns, ticketReservation) => {
      buildTicketReservation(csrfToken, confirm, updated, promoCode, addOns, ticketReservation, userContext);
    }, 300);
  }, []);

  useEffect(() => {
    seatsIOObjectSelected = (object) => {
      var updatedSeatsIOSelectedObjects = [...seatsIOSelectedObjects, object];
      var updatedTicketTypes = [...ticketTypes].map((tt) => {
        var filteredObjects = updatedSeatsIOSelectedObjects.filter((o) =>
          o.category.key.toString() === tt.seating_chart_category_key
        );

        return Object.assign({}, tt, {
          seatsIOObjects: filteredObjects.map((object) => {
            return {
              seatsio_object_id: object.id,
              seat_assignment: selectedObjectLabel(object),
              seat_type: selectedObjectType(object),
              hold_token: object.chart?.holdToken
            }
          }),
          quantity: filteredObjects.length
        });
      });

      seatsIOSelectedObjectsChanged(updatedSeatsIOSelectedObjects);
      ticketTypesChanged(updatedTicketTypes);
      debouncedBuildTicketReservation(csrfToken, confirm, updatedTicketTypes, promoCode, addOns, ticketReservation);

      var updatedItem = updatedTicketTypes.find((ticketType) =>
        ticketType.seating_chart_category_key === object.category.key.toString()
      );
      var items = formatItemsForGoogleTagManager(
        confirm,
        promoCode,
        [updatedItem],
        []
      );
      postToParent("ga.add_to_cart", items)
    };

    seatsIOObjectDeselected = (object) => {
      var updatedSeatsIOSelectedObjects = seatsIOSelectedObjects.filter((o) => o.id !== object.id);
      var updatedTicketTypes = [...ticketTypes].map((tt) => {
        var filteredObjects = updatedSeatsIOSelectedObjects.filter((o) =>
          o.category.key.toString() === tt.seating_chart_category_key
        );

        return Object.assign({}, tt, {
          seatsIOObjects: filteredObjects.map((object) => {
            return {
              seatsio_object_id: object.id,
              seat_assignment: selectedObjectLabel(object),
              seat_type: selectedObjectType(object),
              hold_token: object.chart?.holdToken
            }
          }),
          quantity: filteredObjects.length
        });
      });

      seatsIOSelectedObjectsChanged(updatedSeatsIOSelectedObjects);
      ticketTypesChanged(updatedTicketTypes);
      debouncedBuildTicketReservation(csrfToken, confirm, updatedTicketTypes, promoCode, addOns, ticketReservation);
    };
  }, [seatsIOSelectedObjects, csrfToken, confirm, ticketTypes, promoCode, addOns, ticketReservation]);

  useEffect(() => {
    setVisibleTickets(visibleTicketTypes(confirm, ticketTypes, promoCode));
  }, [confirm, ticketTypes, promoCode])

  useEffect(() => {
    setAvailableTickets(
      visibleTickets.filter((ticketType) => ticketTypeAvailable(ticketType))
    );

    setScheduledTickets(
      visibleTickets
        .filter((ticketType) => ticketType.scheduled)
        .sort((a, b) => new Date(a.start_time) - new Date(b.start_time))
    );
  }, [visibleTickets]);

  useEffect(() => {
    if(Object.keys(seatsIOChart).length > 0){
      seatsIOChart.changeConfig({
        pricing: ticketTypes.map((tt) => {
          return {
            category: tt.seating_chart_category_key,
            price: parseFloat(tt.calculated_price)
          };
        })
      });
    }
  }, [seatsIOChart, promoCode, ticketTypes]);

  useEffect(() => {
    if(Object.keys(seatsIOChart).length > 0){
      seatsIOChart.changeConfig({
        maxSelectedObjects: ticketTypes.map((tt) => {
          return {
            category: tt.seating_chart_category_key,
            quantity: tt.maximum_tickets_during_checkout
          };
        })
      });
    }
  }, [seatsIOChart, ticketTypes]);

  useEffect(() => {
    if(Object.keys(seatsIOChart).length > 0){
      var now = new Date();

      var visibleCategoryIds = visibleTicketTypes(confirm, ticketTypes, promoCode)
        .filter((tt) => {
          var startTime = new Date(tt.start_time);
          var endTime = new Date(tt.end_time);

          return (startTime <= now && now <= endTime);
        })
        .map((tt) => tt.seating_chart_category_key);

      // Empty array means everything is available
      if(visibleCategoryIds.length === 0){
        var mockCategoryId = uuidv4();
        visibleCategoryIds.push(mockCategoryId);
      }

      seatsIOChart.changeConfig({
        availableCategories: visibleCategoryIds
      });
    }
  }, [seatsIOChart, visibleTicketTypes, ticketTypes, promoCode]);

  return (
    <div>
      {availableTickets.length === 0 ? (
        salesStartInFuture(confirm) ? (
          announceListEnabled ? (
            <CalendarEventInterestForm
              confirm={confirm}
              csrfToken={csrfToken}
              interestType="Announce"
            />
          ) : (
            <div className="card border-0 h-100" style={{"background": "#f9f9f9"}}>
              <div className="card-body d-flex align-items-center justify-content-center">
                <div className="text-center">
                  <p className="mb-2"
                    style={{"fontSize": "16px"}}>
                    <strong>Coming soon!</strong>
                  </p>
                  <p className="mb-0">
                    Sales not started yet.
                  </p>
                </div>
              </div>
            </div>
          )
        ) : (
          waitlistEnabled ? (
            <CalendarEventInterestForm
              confirm={confirm}
              csrfToken={csrfToken}
              interestType="Waitlist"
            />
          ) : (
            <div className="card border-0 h-100"
                  style={{"background": "#f9f9f9"}}>
              <div className="card-body d-flex align-items-center justify-content-center">
                <div className="text-center">
                  <p className="mb-2"
                      style={{"fontSize": "16px"}}>
                    <strong>Tickets not currently available</strong>
                  </p>
                  {scheduledTickets.length > 0 ? (
                    <>
                      <p className="mb-0">
                        Online sales for this event are currently not available.
                      </p>
                      <p className="mb-0">
                        {`The next sale will begin on ${scheduledTickets[0].human_start_time}.`}
                      </p>
                    </>
                  ) : (
                    <p className="mb-0">
                      Online sales for this event have ended.
                    </p>
                  )}
                </div>
              </div>
            </div>
          )
        )
      ) : confirm.seatsio_event_id && confirm.seatsio_event_id.length > 0 ? (
        <div className="row mt-4 mb-2">
          {confirm.enable_all_in_pricing ? (
            <p className="w-100 mb-2 small text-muted text-center">
              <i className="fas fa-info-circle mr-1"></i>
              Price includes all fees, including taxes if applicable
            </p>
          ) : null}
          <div className="col-12">
            <div className="card"
                 style={{
                   "border": "1px solid #f3f3f3",
                   "height": "515px"
                 }}>
              <SeatsioSeatingChart workspaceKey={seatsIOPublicKey}
                                   event={confirm.seatsio_event_id}
                                   fitTo={'widthAndHeight'}
                                   onRenderStarted={
                                     chart => {
                                       seatsIOChartChanged(chart);

                                       chart.config.session = "start";
                                       chart.config.onObjectSelected = (object) => seatsIOObjectSelected(object);
                                       chart.config.onObjectDeselected = (object) => seatsIOObjectDeselected(object);
                                       chart.config.selectionValidators = [
                                        ...(no_orphan_seats ? [{ type: 'noOrphanSeats' }] : [])
                                      ];
                                      chart.config.onSelectionValid = () => {
                                        onSelectionChange(true, null);
                                      };
                                      chart.config.onSelectionInvalid = errors => {
                                        onSelectionChange(false, errors);
                                      };
                                     }
                                   }
                                   priceFormatter={
                                     price => formatter.format(price)
                                   }
                                   region="na" />
            </div>
          </div>
        </div>
      ) : (
        <div className="row my-2">
          <div className="col-12">
            <div className="card border-0 h-100"
                 style={{"background": "#f9f9f9"}}>
              <div className="card-body d-flex align-items-center justify-content-center">
                <div className="text-center">
                  <p className="mb-2"
                     style={{"fontSize": "16px"}}>
                    <strong>An error occurred.</strong>
                  </p>
                  <p className="mb-0">
                    Please try again later.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

ChooseSeatedTicketTypes.propTypes = {
  team: PropTypes.object.isRequired,
  csrfToken: PropTypes.string.isRequired,
  confirm: PropTypes.object.isRequired,
  ticketTypes: PropTypes.array.isRequired,
  promoCode: PropTypes.object,
  ticketTypesChanged: PropTypes.func.isRequired,
  buildTicketReservation: PropTypes.func.isRequired,
  visibleTicketTypes: PropTypes.func.isRequired,
  addOns: PropTypes.array,
  ticketReservation: PropTypes.object,
  postToParent: PropTypes.func.isRequired,
  formatItemsForGoogleTagManager: PropTypes.func.isRequired,
  seatsIOPublicKey: PropTypes.string.isRequired,
  seatsIOChart: PropTypes.object,
  seatsIOChartChanged: PropTypes.func.isRequired,
  seatsIOSelectedObjects: PropTypes.array,
  seatsIOSelectedObjectsChanged: PropTypes.func.isRequired,
  selectedObjectLabel: PropTypes.func.isRequired,
  userContext: PropTypes.object,
  selectedObjectType: PropTypes.func.isRequired,
  onSelectionChange: PropTypes.func,
  announceListEnabled: PropTypes.bool,
  waitlistEnabled: PropTypes.bool,
  no_orphan_seats: PropTypes.bool.isRequired
};

export default ChooseSeatedTicketTypes;
