import PropTypes from 'prop-types';
import React from 'react';
import $ from 'jquery';
import { isEqual } from 'lodash';

require('datatables.net-bs4');

class DataTable extends React.Component {
  static propTypes = {
    dataSource: PropTypes.string.isRequired,
    columns: PropTypes.array.isRequired,
    title: PropTypes.string,
    detailTable:PropTypes.bool,
    searchedSearchTerm: PropTypes.string,
    order: PropTypes.array,
    reducedFilters: PropTypes.object,
    onRowClick: PropTypes.func,
    rowCallback: PropTypes.func,
    onInitComplete: PropTypes.func,
    tableClassNames: PropTypes.string,
    onTableElementSet: PropTypes.func,
    createdRow: PropTypes.func,
    zeroRecords: PropTypes.string,
    drawCallback: PropTypes.func,
    colGroup: PropTypes.element
  };

  static defaultProps = {
    reducedFilters: {},
    searchedSearchTerm: "",
    onRowClick: (e, data, row) => {},
    rowCallback: (row, data, displayNum, displayIndex, dataIndex) => {},
    onInitComplete: (settings, json) => {},
    tableClassNames: "",
    onTableElementSet: (el) => {},
    createdRow: (row, data, dataIndex) => {},
    zeroRecords: "&nbsp;",
    drawCallback: (settings) => {}
  };

  constructor(props) {
    super(props);

    this.state = {
      dataSource: this.props.dataSource,
      columns: this.props.columns,
      title: this.props.title,
      searchedSearchTerm: this.props.searchedSearchTerm,
      order: this.props.order,
      reducedFilters: this.props.reducedFilters,
      onRowClick: this.props.onRowClick,
      rowCallback: this.props.rowCallback,
      onInitComplete: this.props.onInitComplete,
      tableClassNames: this.props.tableClassNames,
      onTableElementSet: this.props.onTableElementSet,
      createdRow: this.props.createdRow,
      zeroRecords: this.props.zeroRecords,
      drawCallback: this.props.drawCallback,
      colGroup: this.props.colGroup
    };
  }

  componentDidMount() {
    this.$el = $(this.el);
    this.state.onTableElementSet(this.el);

    var dataTableOptions = {
      "dom": this.props.detailTable ? "<<'header-section' i <'spacer'>>t><'footer-section' pl>" : "<'table-card table-responsive' <'header-section' i <'spacer'>>t><'footer-section' pl>",
      "language": {
        "info": this.props.detailTable || !this.state.title ? "" : "<span class='dataTable-title'><strong><span class='dataTable-dark-text'>" + this.state.title + "</span> <span class='dataTable-title-total'>(_TOTAL_)</span></strong></span>",
        "infoEmpty": this.props.detailTable || !this.state.title ? "" : "<span class='dataTable-title'><strong><span class='dataTable-dark-text'>" + this.state.title + "</span> <span class='dataTable-title-total'>(_TOTAL_)</span></strong></span>",
        "zeroRecords": this.state.zeroRecords,
        "infoFiltered": "",
        "lengthMenu": "Show: _MENU_",
        "search": "",
        "searchPlaceholder": "",
        "paginate": {
          "previous": "&larr;",
          "next": "&rarr;"
        }
      },
      "lengthMenu": [[10, 25, 50, 100], ["10 Rows", "25 Rows", "50 Rows", "100 Rows"]],
      "processing": true,
      "serverSide": true,
      "rowCallback": this.state.rowCallback,
      "drawCallback": this.state.drawCallback,
      "ajax": {
        "url": this.state.dataSource,
        "data": (d) => {
          return Object.assign({}, d, this.state.reducedFilters);
        },
        "dataSrc": (response) => {
          if(response.data.length){
            this.$el.removeClass("data-table-empty");
          } else {
            this.$el.addClass("data-table-empty");
          }

          return response.data;
        }
      },
      "order": this.state.order,
      "pagingType": "simple_numbers",
      "columns": this.state.columns,
      "initComplete": this.state.onInitComplete,
      "createdRow": this.state.createdRow
    };

    if(this.state.searchedSearchTerm.length > 0){
      dataTableOptions = Object.assign({}, dataTableOptions, {
        "oSearch": {"sSearch": this.state.searchedSearchTerm}
      });
    }

    this.dataTable = this.$el.dataTable(dataTableOptions);

    var dataTableForClick = this.$el.DataTable();
    var onRowClickFunc = this.state.onRowClick;
    this.$el.on('click', 'tbody > tr', function(e){
      var data = dataTableForClick.row(this).data();
      onRowClickFunc(e, data, this);
    });
  }

  componentWillUnmount() {
    this.$el.DataTable().destroy();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.searchedSearchTerm !== this.props.searchedSearchTerm) {
      this.$el.DataTable().search(this.props.searchedSearchTerm).draw();
    }

    if (!isEqual(prevProps.reducedFilters, this.props.reducedFilters)) {
      this.setState({reducedFilters: this.props.reducedFilters});

      setTimeout(() => {
        this.$el.DataTable().ajax.reload();
      }, 1)
    }
  }

  render() {
    return (
      <div className="table-responsive">
        <table className={"table " + this.state.tableClassNames} style={{width: "100%"}} ref={el => this.el = el} data-source={this.state.dataSource}>
          {this.state.colGroup ? this.state.colGroup : null}
          <thead>
            <tr>
              {this.state.columns.map((column, i) =>
                <th key={i}>{column.label}</th>
              )}
            </tr>
          </thead>
          <tbody>
          </tbody>
        </table>
      </div>
    );
  }
}

export default DataTable;
