import React from "react";
import { Panel } from "@xyflow/react";

import { useCustomCampaignShowContext } from "../CustomCampaignShowContainer";

const sidebarStyle = {
  minWidth: "300px",
  padding: "20px",
  width: "fit-content",
  height: "fit-content",
  backgroundColor: "white",
  marginRight: "10px",
  borderRadius: "10px",
  boxShadow: "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  color: "#333333",
};

const pillStyle = {
  marginTop: 10,
  fontSize: 10,
  padding: "2px 5px",
  borderRadius: 4,
};

const draggableRowStyle = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 5,
  padding: "5px 10px",
  width: "100%",
  borderRadius: 4,
  border: "1px solid #D5D7DA",
  boxShadow: "0px 1px 2px 0px #0A0D120D",
  cursor: "grab",
  marginTop: 5,
  backgroundColor: "white",
  transform: "translate(0, 0)",
};

export default () => {
  const { setBuildPayload, setBuilding } = useCustomCampaignShowContext();

  const onDragStart = (event, nodeType) => {
    setBuildPayload(nodeType);
    event.dataTransfer.effectAllowed = "move";
  };

  const closeSidebar = () => {
    setBuilding(false);
  };

  return (
    <Panel position="top-right">
      <div style={sidebarStyle}>
        <div className="row no-gutters justify-content-between align-items-flex-start" style={{ width: "100%", marginBottom: 15 }}>
          <div className="figma-text lg semibold">Build</div>
          <button className="btn btn-link" onClick={closeSidebar}>
            <i className="far fa-xmark figma-text xl quinary" />
          </button>
        </div>
        <div style={{
          ...pillStyle,
          color: "#136395",
          backgroundColor: "#DFF0FB",
          border: "1px solid #85C6EF",
        }}>
          Messages
        </div>
        <div draggable onDragStart={(event) => onDragStart(event, { type: "WorkflowNodes::EmailNode" })} style={draggableRowStyle}>
          <i className="far fa-envelope figma-text sm secondary" />
          <div className="figma-text sm secondary semibold">Email</div>
        </div>
        <div style={{...pillStyle,
          color: "#6D9F1E",
          backgroundColor: "#E9F7D4",
          border: "1px solid #BEE77E",
        }}>
          Data
        </div>
        <div draggable onDragStart={(event) => onDragStart(event, { type: "WorkflowNodes::ManualSegmentUpdateNode" })} style={draggableRowStyle}>
          <i className="far fa-wrench figma-text sm secondary" />
          <div className="figma-text sm secondary semibold">Manual segment update</div>
        </div>
        <div style={{
          ...pillStyle,
          color: "#B54708",
          backgroundColor: "#FEDF89",
          border: "1px solid #FDB022",
        }}>
          Delays
        </div>
        <div draggable onDragStart={(event) => onDragStart(event, { type: "WorkflowNodes::TimeDelayNode" })} style={draggableRowStyle}>
          <i className="far fa-stopwatch figma-text sm secondary" />
          <div className="figma-text sm secondary semibold">Time delay</div>
        </div>
        <div style={{
          ...pillStyle,
          color: "#5925DC",
          backgroundColor: "#D9D6FE",
          border: "1px solid #9B8AFB",
        }}>
          Flow Control
        </div>
        <div draggable onDragStart={(event) => onDragStart(event, { type: "WorkflowNodes::TrueFalseBranchNode" })} style={draggableRowStyle}>
          <i className="far fa-code-merge figma-text sm secondary" />
          <div className="figma-text sm secondary semibold">True/False Branch</div>
        </div>
      </div>
    </Panel>
  );
};
