import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const axios = require('axios').default;
import Popup from "reactjs-popup";

const calendarSyncContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "688px",
  "padding": "0px"
}

const AnnouncementAlert = ({
  csrfToken,
  user,
  team
}) => {
  const [announcementAlert, setAnnouncementAlert] = useState({});
  const [announcement, setAnnouncement] = useState({});

  useEffect(() => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
    axios.post("/announcement_alerts")
      .then(({ data }) => {
        setAnnouncementAlert(data);
      })
  }, []);

  useEffect(() => {
    if (announcementAlert && announcementAlert.announcement) {
      setAnnouncement(announcementAlert.announcement);
    } else {
      setAnnouncement({});
    }
  }, [announcementAlert]);

  const dismissAnnouncementAlert = () => {
    if (Object.keys(announcementAlert).length === 0) {
      return;
    }

    const now = new Date();

    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
    axios.patch(`/announcement_alerts/${announcementAlert.id}`, {
        announcement_alert: {
          dismissed_at: now.toISOString()
        }
      })
      .then(({ data }) => {
        setAnnouncementAlert({});
      })
  }

  return (
    <div>
      <Popup
        open={Object.keys(announcement || {}).length > 0}
        contentStyle={calendarSyncContentStyle}
        className={"popup-modal"}
        closeOnDocumentClick={false}
        onClose={
          () => {
            dismissAnnouncementAlert();
          }
        }
      >
        <div className="popup-modal-container">
          <div className="row m-0">
            <div className="col-12" style={{"borderBottom": "1px solid #E9EAEB"}}>
              <div className="d-flex" style={{"padding": "24px 9px"}}>
                <div className="flex-grow-1">
                  <p style={{
                        "fontSize": "18px",
                        "fontWeight": "600",
                        "marginBottom": "1px"
                      }}>
                    What's new
                  </p>
                  <p className='mb-0' style={{"fontSize": "14px"}}>
                    New features, bug fixes, and other content you might have missed.
                  </p>
                </div>
                <div>
                  <a href="#"
                      className="text-muted"
                      onClick={(e) => {
                        e.preventDefault();
                        dismissAnnouncementAlert();
                      }}
                      style={{"fontSize": "24px"}}>
                    <i className="fal fa-times text-muted"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          {announcement && announcement.id ? (
            <div className="row m-0">
              <div className="col-12" style={{"borderBottom": "1px solid #E9EAEB"}}>
                <div style={{"padding": "20px 9px 32px 9px"}}>
                  <div className='card mb-0' style={{"border": "1px solid #E9EAEB", "borderRadius": "16px"}}>
                    <div className='card-body' style={{"padding": "24px"}}>
                      <p className='mb-0' style={{"fontSize": "14px", "fontWeight": "600"}}>
                        {announcement.human_published_at}
                      </p>
                      <p className='mb-0' style={{"fontSize": "18px", "fontWeight": "600", "marginTop": "5px"}}>
                        {announcement.name}
                      </p>
                      <p className='mb-0' style={{"fontSize": "16px", "marginTop": "5px"}}>
                        {announcement.snippet}
                      </p>
                      <div className='row' style={{"marginTop": "25px"}}>
                        <div className='col-12'>
                          <span style={{
                                  "borderRadius": "9px",
                                  "padding": "2px 10px",
                                  "backgroundColor": "#FAFAFA",
                                  "border": "1px solid #E9EAEB",
                                  "fontSize": "14px",
                                  "marginRight": "8px"
                                }}>
                            {announcement.category}
                          </span>
                          <span style={{
                                  "borderRadius": "9px",
                                  "padding": "2px 10px",
                                  "backgroundColor": "#F6FAFE",
                                  "border": "1px solid #DFF0FB",
                                  "fontSize": "14px",
                                  "color": "#136395"
                                }}>
                            {announcement.label}
                          </span>
                        </div>
                      </div>
                      <div className='row' style={{"marginTop": "25px"}}>
                        <div className='col-12'>
                          <a href="#"
                              onClick={
                                (e) => {
                                  e.preventDefault();

                                  setAnnouncementAlert({});
                                  window.open(`/announcements/${announcement.id}`, "_blank");
                                }
                              }
                              className="btn btn-outline-primary announcement-btn"
                              style={{
                                "fontSize": "14px",
                                "padding": "8px 12px",
                                "borderRadius": "8px",
                                "border": "1px solid #B2DBF5",
                                "boxShadow": "0px 0px 0px 1px rgba(10, 13, 18, 0.18) inset, 0px -2px 0px 0px rgba(10, 13, 18, 0.05) inset, 0px 1px 2px 0px rgba(10, 13, 18, 0.05)"
                              }}
                              target="_blank">
                            Learn more
                            <i className="far fa-arrow-up fa-rotate-by" style={{"--fa-rotate-angle": "45deg", "marginLeft": "7px"}}></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <div className="row m-0">
            <div className="col-12 text-right">
              <div style={{"padding": "24px 9px"}}>
                <a href="/announcements"
                    style={{"fontSize": "16px", "fontWeight": "600"}}
                    className='btn btn-primary'>
                  View all updates
                </a>
              </div>
            </div>
          </div>
        </div>
      </Popup>
    </div>
  )
};

AnnouncementAlert.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  team: PropTypes.object.isRequired
};

export default AnnouncementAlert;
