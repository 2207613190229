import PropTypes from 'prop-types';
import React from 'react';

import AddOnSeatingChart from './../../AddOnSeatingChart/components/AddOnSeatingChart';

const ChooseSeatedAddOns = ({
  team,
  csrfToken,
  confirm,
  ticketTypes,
  addOns,
  buildTicketReservation,
  addOnsChanged,
  seatsIOPublicKey,
  selectedObjectLabel,
  selectedObjectType,
  orderType,
  checkoutGroupItem
}) => {
  const hasAddOnWithSharedInventory = (addOns) => {
    return addOns.some((ao) => ao.share_inventory_with_id);
  };

  return (
    hasAddOnWithSharedInventory(addOns) ? (
      addOns.filter((ao) => ao.seating_chart_category_key).sort((a, b) => a.name.localeCompare(b.name)).map((addOn, index) =>
        <AddOnSeatingChart team={team}
                          key={index}
                          csrfToken={csrfToken}
                          confirm={confirm}
                          ticketTypes={ticketTypes}
                          addOns={addOns}
                          visibleAddOns={addOns}
                          promoCode={{}}
                          addOnsChanged={addOnsChanged}
                          ticketReservation={{}}
                          postToParent={() => {}}
                          formatItemsForGoogleTagManager={() => {}}
                          seatsIOPublicKey={seatsIOPublicKey}
                          selectedObjectLabel={selectedObjectLabel}
                          selectedObjectType={selectedObjectType}
                          buildTicketReservation={
                            (updated) => {
                              buildTicketReservation(csrfToken, team, confirm, orderType, ticketTypes, updated);
                            }
                          }
                          userContext={{}}
                          addOnsToSell={[addOn]}
                          sharedInventoryMode={true}
                          showDescription={false}
                          displayChartText={"Choose seat"}
                          displayChartButtonClass={"btn btn-outline-primary"}
                          checkoutGroupItem={checkoutGroupItem} />
      )
    ) : (
      <AddOnSeatingChart team={team}
                          csrfToken={csrfToken}
                          confirm={confirm}
                          ticketTypes={ticketTypes}
                          addOns={addOns}
                          visibleAddOns={addOns}
                          promoCode={{}}
                          addOnsChanged={addOnsChanged}
                          ticketReservation={{}}
                          postToParent={() => {}}
                          formatItemsForGoogleTagManager={() => {}}
                          seatsIOPublicKey={seatsIOPublicKey}
                          selectedObjectLabel={selectedObjectLabel}
                          selectedObjectType={selectedObjectType}
                          buildTicketReservation={
                            (updated) => {
                              buildTicketReservation(csrfToken, team, confirm, orderType, ticketTypes, updated);
                            }
                          }
                          userContext={{}}
                          addOnsToSell={addOns}
                          sharedInventoryMode={false}
                          showDescription={false}
                          displayChartText={"Choose seat"}
                          displayChartButtonClass={"btn btn-outline-primary"}
                          checkoutGroupItem={checkoutGroupItem} />
    )
  );
}

ChooseSeatedAddOns.propTypes = {
  team: PropTypes.object.isRequired,
  csrfToken: PropTypes.string.isRequired,
  confirm: PropTypes.object.isRequired,
  ticketTypes: PropTypes.array.isRequired,
  addOns: PropTypes.array.isRequired,
  buildTicketReservation: PropTypes.func.isRequired,
  addOnsChanged: PropTypes.func.isRequired,
  seatsIOPublicKey: PropTypes.string.isRequired,
  selectedObjectLabel: PropTypes.func.isRequired,
  selectedObjectType: PropTypes.func.isRequired,
  orderType: PropTypes.string,
  checkoutGroupItem: PropTypes.object
};

export default ChooseSeatedAddOns;
