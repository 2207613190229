import React, { createContext, useState, useContext, useEffect } from "react";
import { ToastContainer } from 'react-toastify';
import { ReactFlowProvider } from '@xyflow/react';
import axios from "axios";

import CustomCampaignShow from "./CustomCampaignShow";
import { toastSuccess } from "../../../shared/toastHelper";

const CustomCampaignShowContext = createContext();

export function useCustomCampaignShowContext() {
  return useContext(CustomCampaignShowContext);
}

const CustomCampaignShowProvider = (props) => {
  const {
    csrfToken,
    user,
    team,
    customCampaign,
  } = props;

  const [buildPayload, setBuildPayload] = useState(null);
  const [building, setBuilding] = useState(false);
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedNodeData, setSelectedNodeData] = useState({});
  const [selectedNodeWorkflowErrors, setSelectedNodeWorkflowErrors] = useState({});
  const [edges, setEdges] = useState(customCampaign.workflow_edges);
  const [nodes, setNodes] = useState(customCampaign.workflow_nodes);
  const [targetDropZoneEdgeInternalId, setTargetDropZoneEdgeInternalId] = useState(0);

  useEffect(() => {
    setSelectedNodeData(selectedNode?.data?.nodeData || {});
    setSelectedNodeWorkflowErrors(selectedNode?.data?.workflowErrors || {});
  }, [selectedNode]);

  const updateSelectedNodeData = (onSuccess=()=>{}) => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
    axios.patch(`/teams/${team.id}/custom_campaigns/${customCampaign.id}/workflow_nodes/${selectedNode.data.internalId}`, {
      workflow_node: {
        node_data: selectedNodeData,
      }
    })
      .then(({ data: { workflow_node, custom_campaign } }) => {
        setNodes(custom_campaign.workflow_nodes);
        setSelectedNode(workflow_node);
        toastSuccess("Updated successfully");
        onSuccess();
      })
      .catch(error => {
        console.error("error", error);
        toastError("Error updating node");
      });
  };

  const checkSaveDisabled = _.debounce((selectedNodeData) => {
    if (!selectedNode) {
      return;
    } else if (_.isEqual(selectedNodeData, selectedNode.data.nodeData)) {
      setSelectedNodeWorkflowErrors(selectedNode.data.workflowErrors);
    } else {
      axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
      axios.post(`/teams/${team.id}/custom_campaigns/${customCampaign.id}/workflow_nodes/${selectedNode.data.internalId}/validate_node_data`, {
        workflow_node: {
          node_data: selectedNodeData,
        }
      })
        .then(({ data }) => {
          setSelectedNodeWorkflowErrors(data);
        })
        .catch(error => {
          console.error("error", error);
        });
    }
  }, 100);

  useEffect(() => {
    checkSaveDisabled(selectedNodeData);
  }, [selectedNodeData]);

  const value = {
    csrfToken,
    user,
    team,
    buildPayload,
    setBuildPayload,
    building,
    setBuilding,
    nodes,
    setNodes,
    edges,
    setEdges,
    customCampaign,
    targetDropZoneEdgeInternalId,
    setTargetDropZoneEdgeInternalId,
    selectedNode,
    setSelectedNode,
    selectedNodeData,
    setSelectedNodeData,
    selectedNodeWorkflowErrors,
    setSelectedNodeWorkflowErrors,
    updateSelectedNodeData,
  };

  return (
    <CustomCampaignShowContext.Provider value={value}>
      {props.children}
    </CustomCampaignShowContext.Provider>
  );
};

const CustomCampaignShowContainer = (props) => (
  <CustomCampaignShowProvider {...props}>
    <ToastContainer />
    <ReactFlowProvider>
      <CustomCampaignShow />
    </ReactFlowProvider>
  </CustomCampaignShowProvider>
);

export default CustomCampaignShowContainer;
