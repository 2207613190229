// Simple example of a React "smart" component

import { connect } from 'react-redux';
import CalendarViewV2 from '../components/CalendarViewV2';
import * as actions from '../actions/calendarViewV2ActionCreators';
import * as eventFormActions from '../../EventForm/actions/eventFormActionCreators';

// Which part of the Redux global state does our component want to receive as props?
const mapStateToProps = (state) => ({
  browser: state.calendarView.browser,
  csrfToken: state.calendarView.csrfToken,
  user: state.calendarView.user,
  team: state.calendarView.team,
  dataTableTitle: state.calendarView.dataTableTitle,
  dataTableDataSource: state.calendarView.dataTableDataSource,
  dataTableOrder: state.calendarView.dataTableOrder,
  dataTableColumns: state.calendarView.dataTableColumns,
  dataTableFilters: state.calendarView.dataTableFilters,
  venuesOptions: state.calendarView.venuesOptions,
  venueSelected: state.calendarView.venueSelected,
  venueOwnerships: state.calendarView.venueOwnerships,
  searchTerm: state.calendarView.searchTerm,
  events: state.calendarView.events,
  calendarEventConflicts: state.calendarView.calendarEventConflicts,
  calendarEventConflictsModalOpen: state.calendarView.calendarEventConflictsModalOpen,
  onSaleEnabled: state.calendarView.onSaleEnabled,
  preSaleEnabled: state.calendarView.preSaleEnabled,
  announcedEnabled: state.calendarView.announcedEnabled,
  confirmedEnabled: state.calendarView.confirmedEnabled,
  holdsEnabled: state.calendarView.holdsEnabled,
  pastEventsEnabled: state.calendarView.pastEventsEnabled,
  cancelledEventsEnabled: state.calendarView.cancelledEventsEnabled,
  calendarStartDate: state.calendarView.calendarStartDate,
  holdGroup: state.eventForm.holdGroup,
  confirm: state.eventForm.confirm,
  submittingForm: state.eventForm.submittingForm,
  calendarEventTitle: state.eventForm.calendarEventTitle,
  calendarEventArtists: state.eventForm.calendarEventArtists,
  calendarEventType: state.eventForm.calendarEventType,
  calendarEventTicketForecast: state.eventForm.calendarEventTicketForecast,
  calendarEventNoteEditorState: state.eventForm.calendarEventNoteEditorState,
  calendarEventErrors: state.eventForm.calendarEventErrors,
  userChangedCalendarEventTitle: state.eventForm.userChangedCalendarEventTitle,
  holdGroupDates: state.eventForm.holdGroupDates,
  dataTableEl: state.calendarView.dataTableEl,
  selectedPromoter: state.eventForm.selectedPromoter,
  buyers: state.eventForm.buyers,
  promoters: state.eventForm.promoters,
  selectedBuyer: state.eventForm.selectedBuyer,
  holdGroupDeletion: state.eventForm.holdGroupDeletion,
  doorsTimeDefault: state.eventForm.doorsTimeDefault,
  eventEndTimeDefault: state.eventForm.eventEndTimeDefault,
  userChangedDoorsTime: state.eventForm.userChangedDoorsTime,
  userChangedEventEndTime: state.eventForm.userChangedEventEndTime,
  currentTeamMembership: state.calendarView.currentTeamMembership,
  checkAvailsIsOpen: state.calendarView.checkAvailsIsOpen,
  checkAvailsSettings: state.calendarView.checkAvailsSettings,
  calendarSyncIsOpen: state.calendarView.calendarSyncIsOpen,
  checkAvailsDates: state.calendarView.checkAvailsDates,
  checkAvailsCalendarEvents: state.calendarView.checkAvailsCalendarEvents,
  checkAvailsLoading: state.calendarView.checkAvailsLoading
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateSelectedVenue: (venues, selected) => dispatch(actions.updateSelectedVenue(venues, selected)),
    reloadDataTable: () => dispatch(actions.reloadDataTable()),
    onInit: (team, selectedVenues, selectedTags, user, dataTableFilters) => dispatch(actions.onInit(dispatch, team, selectedVenues, selectedTags, user, dataTableFilters)),
    fetchTeamVenueConflicts: (team, selectedVenues, user) => dispatch(actions.fetchTeamVenueConflicts(dispatch, team, selectedVenues, user)),
    closeCalendarEventConflictsModal: () => dispatch(actions.closeCalendarEventConflictsModal()),
    confirmTeamVenueConflicts: (csrfToken, team, calendarEventConflicts, selectedVenues, selectedTags) => dispatch(actions.confirmTeamVenueConflicts(dispatch, csrfToken, team, calendarEventConflicts, selectedVenues, selectedTags)),
    saveUserCalendarStartDay: (csrfToken, calendarStartDay) => dispatch(actions.saveUserCalendarStartDay(dispatch, csrfToken, calendarStartDay)),
    calendarEventConflictMergeCheckboxClicked: (calendarEventConflict, checked) => dispatch(actions.calendarEventConflictMergeCheckboxClicked(calendarEventConflict, checked)),
    toggleOnSaleEnabled: (onSaleEnabled) => dispatch(actions.toggleOnSaleEnabled(onSaleEnabled)),
    togglePreSaleEnabled: (preSaleEnabled) => dispatch(actions.togglePreSaleEnabled(preSaleEnabled)),
    toggleAnnouncedEnabled: (announcedEnabled) => dispatch(actions.toggleAnnouncedEnabled(announcedEnabled)),
    toggleConfirmedEnabled: (confirmedEnabled) => dispatch(actions.toggleConfirmedEnabled(confirmedEnabled)),
    toggleHoldsEnabled: (holdsEnabled) => dispatch(actions.toggleHoldsEnabled(holdsEnabled)),
    togglePastEventsEnabled: (pastEventsEnabled) => dispatch(actions.togglePastEventsEnabled(pastEventsEnabled)),
    toggleCancelledEventsEnabled: (cancelledEventsEnabled) => dispatch(actions.toggleCancelledEventsEnabled(cancelledEventsEnabled)),
    calendarStartDateUpdated: (date) => dispatch(actions.calendarStartDateUpdated(date)),
    submitNewEventForm: (csrfToken, team, closeFunction, calendarEventType, calendarEventArtists, calendarEventTitle, venueSelected, ticketForecast, calendarEventNoteEditorState, confirm, holdGroup, onSuccess, selectedPromoter, selectedBuyer) => dispatch(eventFormActions.submitNewEventForm(dispatch, csrfToken, team, closeFunction, calendarEventType, calendarEventArtists, calendarEventTitle, venueSelected, ticketForecast, calendarEventNoteEditorState, confirm, holdGroup, onSuccess, selectedPromoter, selectedBuyer)),
    fetchHoldAvailability: (team, venueId, date) => dispatch(eventFormActions.fetchHoldAvailability(dispatch, team, venueId, date)),
    updateCalendarEventTitle: (title) => dispatch(eventFormActions.updateCalendarEventTitle(title)),
    updateCalendarEventArtists: (artists, userChangedCalendarEventTitle) => dispatch(eventFormActions.updateCalendarEventArtists(artists, userChangedCalendarEventTitle)),
    updateCalendarEventType: (calendarEventType) => dispatch(eventFormActions.updateCalendarEventType(calendarEventType)),
    calendarEventTicketForecastChanged: (ticketForecast) => dispatch(eventFormActions.calendarEventTicketForecastChanged(ticketForecast)),
    updateCalendarEventNoteEditorState: (noteEditorState) => dispatch(eventFormActions.updateCalendarEventNoteEditorState(noteEditorState)),
    calendarEventFormClosed: (onClose) => dispatch(eventFormActions.calendarEventFormClosed(dispatch, onClose)),
    holdGroupCalendarDateClicked: (date, selected) => dispatch(eventFormActions.holdGroupCalendarDateClicked(date, selected)),
    holdGroupHoldPositionUpdated: (date, position) => dispatch(eventFormActions.holdGroupHoldPositionUpdated(date, position)),
    holdGroupHoldRemoved: (date) => dispatch(eventFormActions.holdGroupHoldRemoved(date)),
    confirmChanged: (confirm) => dispatch(eventFormActions.confirmChanged(confirm)),
    confirmStartDateUpdated: (date) => dispatch(eventFormActions.confirmStartDateUpdated(date)),
    confirmStartTimeUpdated: (time) => dispatch(eventFormActions.confirmStartTimeUpdated(time)),
    dataTableElChanged: (el) => dispatch(actions.dataTableElChanged(el)),
    buyersChanged: (buyers) => dispatch(eventFormActions.buyersChanged(buyers)),
    promotersChanged: (promoters) => dispatch(eventFormActions.promotersChanged(promoters)),
    selectedPromoterChanged: (promoter) => dispatch(eventFormActions.selectedPromoterChanged(promoter)),
    selectedBuyerChanged: (buyer) => dispatch(eventFormActions.selectedBuyerChanged(buyer)),
    holdGroupDeletionChanged: (holdGroupDeletion) => dispatch(eventFormActions.holdGroupDeletionChanged(holdGroupDeletion)),
    holdGroupDeletionReasonChanged: (reason) => dispatch(eventFormActions.holdGroupDeletionReasonChanged(reason)),
    holdGroupDeletionExplanationChanged: (explanation) => dispatch(eventFormActions.holdGroupDeletionExplanationChanged(explanation)),
    holdGroupDeletionFollowUpChanged: (followUp) => dispatch(eventFormActions.holdGroupDeletionFollowUpChanged(followUp)),
    holdGroupDeletionFollowUpAtChanged: (followUpAt) => dispatch(eventFormActions.holdGroupDeletionFollowUpAtChanged(followUpAt)),
    deleteHoldGroup: (csrfToken, team, closeFunction, holdGroup, holdGroupDeletion) => dispatch(eventFormActions.deleteHoldGroup(dispatch, csrfToken, team, closeFunction, holdGroup, holdGroupDeletion)),
    searchTermChanged: (term) => dispatch(actions.searchTermChanged(term)),
    saveTeamMembershipCalendarState: (csrfToken, team, teamMembership, teamMembershipParams) => dispatch(actions.saveTeamMembershipCalendarState(dispatch, csrfToken, team, teamMembership, teamMembershipParams)),
    userChangedDoorsTimeChanged: (userChangedDoorsTime) => dispatch(eventFormActions.userChangedDoorsTimeChanged(userChangedDoorsTime)),
    userChangedEventEndTimeChanged: (userChangedEventEndTime) => dispatch(eventFormActions.userChangedEventEndTimeChanged(userChangedEventEndTime)),
    applyTimeDefaults: (confirm, doorsTimeDefault, eventEndTimeDefault, canUpdateDoorsTime, canUpdateEventEndTime) => dispatch(eventFormActions.applyTimeDefaults(dispatch, confirm, doorsTimeDefault, eventEndTimeDefault, canUpdateDoorsTime, canUpdateEventEndTime)),
    checkAvailsIsOpenChanged: (checkAvailsIsOpen) => dispatch(actions.checkAvailsIsOpenChanged(checkAvailsIsOpen)),
    checkAvailsSettingsChanged: (checkAvailsSettings) => dispatch(actions.checkAvailsSettingsChanged(checkAvailsSettings)),
    calendarSyncIsOpenChanged: (calendarSyncIsOpen) => dispatch(actions.calendarSyncIsOpenChanged(calendarSyncIsOpen)),
    checkAvailsDatesChanged: (checkAvailsDates) => dispatch(actions.checkAvailsDatesChanged(checkAvailsDates)),
    checkAvailsCalendarEventsChanged: (checkAvailsCalendarEvents) => dispatch(actions.checkAvailsCalendarEventsChanged(checkAvailsCalendarEvents)),
    fetchCheckAvailsCalendarEvents: (team, selectedVenues, checkAvailsDates) => dispatch(actions.fetchCheckAvailsCalendarEvents(dispatch, team, selectedVenues, checkAvailsDates)),
    holdGroupChanged: (holdGroup) => dispatch(eventFormActions.holdGroupChanged(holdGroup)),
    fetchCalendarEventsForSelectedVenues: (team, selectedVenues, selectedTags) => dispatch(actions.fetchCalendarEventsForSelectedVenues(dispatch, team, selectedVenues, selectedTags)),
    dataTableFiltersChanged: (dataTableFilters) => dispatch(actions.dataTableFiltersChanged(dataTableFilters)),
    currentTeamMembershipChanged: (currentTeamMembership) => dispatch(actions.currentTeamMembershipChanged(currentTeamMembership)),
    venueOwnershipsChanged: (venueOwnerships) => dispatch(actions.venueOwnershipsChanged(venueOwnerships))
  }
};

// Don't forget to actually use connect!
// Note that we don't export HelloWorld, but the redux "connected" version of it.
// See https://github.com/reactjs/react-redux/blob/master/docs/api.md#examples
export default connect(mapStateToProps, mapDispatchToProps)(CalendarViewV2);
