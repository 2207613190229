import React from 'react';

const selectTriggerBoxStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "110px",
  padding: "5px 8px",
  borderRadius: "4px",
  marginTop: "10px"
};

export default ({ isSelected }) => {
  return (
    <div style={{
      ...selectTriggerBoxStyle,
      border: isSelected ? "1px solid #B2DBF5" : "1px solid #D5D7DA",
      color: isSelected ? "#136395" : "#414651",
    }} className="figma-text xs semibold">
      Select Trigger
    </div>
  );
};
