import React from "react";
import { Panel } from "@xyflow/react";

import { useCustomCampaignShowContext } from "./CustomCampaignShowContainer";
 
export default () => {
  const { customCampaign } = useCustomCampaignShowContext();
 
  const sidebarStyle = {
    width: "200px",
    height: "fit-content",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: "10px",
    borderRadius: "10px",
    boxShadow: "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  };

  return (
    <Panel position="top-left">
      <div style={sidebarStyle}>
        <div>Custom Campaign</div>
        <div>
          {customCampaign.name}
        </div>
      </div>
    </Panel>
  );
};
