import React, { useEffect, useCallback } from "react";
import {
  ReactFlow,
  Background,
  useReactFlow,
  useStoreApi,
  useNodesState,
  useEdgesState,
  MarkerType,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import axios from "axios";

import { useCustomCampaignShowContext } from "./CustomCampaignShowContainer";
import WorkflowNode from "./Nodes/WorkflowNode";
import DropZoneNode from "./Nodes/DropZoneNode";
import BuildingSidebar from "./Sidebars/BuildingSidebar";
import NodeSidebar from "./Sidebars/NodeSidebar";
import ControlPanel from "./ControlPanel";
import CustomCampaignInfoPanel from "./CustomCampaignInfoPanel";

const CustomCampaignShow = () => {
  const {
    csrfToken,
    team,
    customCampaign,
    buildPayload,
    nodes,
    edges,
    setNodes,
    setEdges,
    building,
    targetDropZoneEdgeInternalId,
    setTargetDropZoneEdgeInternalId,
    selectedNode,
    setSelectedNode,
  } = useCustomCampaignShowContext();

  const { screenToFlowPosition, getNode, fitView } = useReactFlow();

  const store = useStoreApi();

  const [flowEdges, setFlowEdges, onFlowEdgesChange] = useEdgesState([]);
  const [flowNodes, setFlowNodes, onFlowNodesChange] = useNodesState([]);

  const debouncedFitViewOnNode = _.debounce((selectedNode) => {
    if (nodes.length > 5) {
      fitView(
        {
          nodes: [selectedNode],
          duration: 750,
          minZoom: 1.5,
          maxZoom: 1.5,
        }
      );
    } else {
      fitView(
        {
          nodes: flowNodes,
          duration: 750,
        }
      );
    }
  }, 50);

  useEffect(() => {
    if (!!selectedNode) {
      debouncedFitViewOnNode(selectedNode);
    }
  }, [selectedNode]);

  useEffect(() => {
    let newNodes = nodes;
    let newEdges = edges;

    if (building) {
      const edgesToInsert = [];
      const nodesToInsert = [];
      const edgesToRemove = [];

      let index = 1;

      edges.map(edge => {
        const sourceNode = getNode(edge.source);
        const targetNode = getNode(edge.target);

        const newNode = {
          id: `dropZoneNode_${index++}`,
          type: "WorkflowNodes::DropZoneNode",
          position: {
            x: (targetNode.position.x),
            y: (sourceNode.position.y + targetNode.position.y) / 2,
          },
          data: {
            edgeInternalId: edge.internal_id,
          }
        };

        nodesToInsert.push(newNode);
        edgesToInsert.push({
          id: `${sourceNode.id}-${newNode.id}`,
          source: sourceNode.id,
          target: newNode.id,
          hideArrow: true,
        });

        edgesToInsert.push({
          id: `${newNode.id}-${targetNode.id}`,
          source: newNode.id,
          target: targetNode.id,
        });

        edgesToRemove.push(edge.id);
      });

      newNodes = nodes.concat(nodesToInsert);
      newEdges = edges.filter(edge => !edgesToRemove.includes(edge.id)).concat(edgesToInsert);
    }

    setFlowNodes(newNodes.map(node => ({
      ...node,
      deletable: false,
      selectable: node.type !== "WorkflowNodes::ExitNode",
      data: {
        ...node.data,
        handleClick: (data) => { console.log("clicked", data); setSelectedNode(node); },
      },
    })));

    setFlowEdges(newEdges.map(edge =>({
      ...edge,
      selectable: false,
      deletable: false,
      markerEnd: {
        type: edge.hideArrow ? undefined : MarkerType.ArrowClosed,
      },
    })));
  }, [building, nodes, edges]);

  const getClosestDropZoneNode = (node) => {
    const { nodeLookup } = store.getState();

    for (const n of nodeLookup.values()) {
      if (n.id !== node.id && n.type === "WorkflowNodes::DropZoneNode") {
        const dx = n.internals.positionAbsolute.x - node.position.x + 90;
        const dy = n.internals.positionAbsolute.y - node.position.y + 10;
        const d = Math.sqrt(dx * dx + dy * dy);

        if (d < 50) {
          return n;
        }
      }
    }
  };

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";

    const position = screenToFlowPosition({
      x: event.clientX,
      y: event.clientY,
    });

    const newNode = {
      id: "onDropTempNode",
      position,
    };

    const dropZoneNode = getClosestDropZoneNode(newNode);
    setTargetDropZoneEdgeInternalId(dropZoneNode?.data?.edgeInternalId || 0);
  }, []);

  const onDrop = useCallback((event) => {
    event.preventDefault();

    if (targetDropZoneEdgeInternalId) {
      axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
      axios.post(`/teams/${team.id}/custom_campaigns/${customCampaign.id}/workflow_edges/${targetDropZoneEdgeInternalId}/workflow_nodes`, {
        workflow_node: buildPayload,
      })
        .then(response => {
          setNodes(response.data.custom_campaign.workflow_nodes);
          setEdges(response.data.custom_campaign.workflow_edges);
          setSelectedNode(response.data.workflow_node);
        })
        .catch(error => {
          console.error("error", error);
        });
    } else {
      console.log("no drop zone node found");
    }

    setTargetDropZoneEdgeInternalId(0);
  }, [screenToFlowPosition, buildPayload, targetDropZoneEdgeInternalId]);

  const nodeTypes = {
    "WorkflowNodes::TriggerNode": WorkflowNode,
    "WorkflowNodes::EmailNode": WorkflowNode,
    "WorkflowNodes::ManualSegmentUpdateNode": WorkflowNode,
    "WorkflowNodes::TimeDelayNode": WorkflowNode,
    "WorkflowNodes::TrueFalseBranchNode": WorkflowNode,
    "WorkflowNodes::ExitNode": WorkflowNode,
    "WorkflowNodes::DropZoneNode": DropZoneNode,
  };

  return (
    <div style={{ height: "85vh" }}>
      <ReactFlow
        nodes={flowNodes}
        edges={flowEdges}
        onNodesChange={onFlowNodesChange}
        onEdgesChange={onFlowEdgesChange}
        nodeTypes={nodeTypes}
        onDrop={onDrop}
        onDragOver={onDragOver}
        nodesDraggable={false}
        nodeOrigin={[0.5, 0.5]}
        fitView
      >
        <ControlPanel />
        <CustomCampaignInfoPanel />
        { selectedNode ? <NodeSidebar /> : building ? <BuildingSidebar /> : null }
        <Background variant="dots" gap={24} size={2} />
      </ReactFlow>
    </div>
  );
};

export default CustomCampaignShow;
